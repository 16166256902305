/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
//@import "~@ng-select/ng-select/themes/default.theme.css";
@import 'src/theme/ng-select.scss';
@import '~@angular/material/prebuilt-themes/indigo-pink.css'; 

// for mobile

.header-segment-top{
    margin: 8px 8px 0;
    height: 40px;
    --background: transparent;
    ion-segment-button{
        // --background-focused: red;
        --padding-bottom: 1px;
        --background-checked: #F37921;
        --border-radius: 30px;
        --color: #fff;
        --color-checked: #fff;
        // font-size: 14px;
        font-size: var(--ion-smaller-font-size);
        font-family: nunitoRegular;
        height: 38px;
        --indicator-height: 0;
        min-height: 30px;
        --background: transparent;
        margin: 0 8px;
        --border-width: 2px;
        --border-color: #aca6d8;
        text-transform: capitalize;
        --ripple-color: transparent;
    }
    .segment-button-checked {
        --border-width: 0 !important;
        // min-height: 34px !important;
        border: 2px solid #f37921;
        background: #F37921;
        font-weight: normal;
        font-family: nunitoBold;
    }
}

.displayNone{
    display: none;
}

.client-tabs{
    ion-segment-button{
        --color: #000000;
    }
}

.header-segment-bottom{
    height: 42px;
    margin: 0 16px;
    min-height: 42px;
    // --background: transparent;
    ion-segment-button{
        text-transform: none;
        height: 42px;
        min-height: 42px;
        --color-checked: #2D197F;
        // --color: #a1abb4;
        --color: var(--ion-gray-primary-color);
        font-family: nunitoLight;
    }
    .segment-button-checked {
        // --indicator-height: 4px;
        --indicator-height: 0px; 
        font-size: 15px;
        // font-family: nunitoSemiBold;
        font-family: 'nunitoBold';
        --color: #2D197F;
        font-weight: bold;
            ion-button{
                --color: #2D197F;
                font-weight: bold;
            }  
        }
    ion-button{
        --color: #586A7A;
    }
}

.orange-border-segment {
    justify-content: flex-start;
    height: 38px;
    min-height: 38px;
    overflow: auto;
    ion-segment-button {
        --color: #83838B;
        --color-checked: #212121;
        font-size: 13px;
        --indicator-color: #F37921;
        text-transform: none;
        height: 36px;
        min-height: 36px;
    }
    .segment-button-checked {
        font-family: 'nunitoSemiBold';
    }
}

.header-search-noti{
    display: inline-block;
    float: right;
}

.modal__close:hover :after, .modal__close:hover:before{
    background-color: #747474 !important;
}


.height-unset {
    height: unset !important;
}

.max-height-unset {
    max-height: unset !important;
}

.overflow-unset {
    overflow: unset !important;
}

// snapshot phase two design
.snapshot-block {
    .title-heading {
        background: #F2F4F4;
        padding: 10px 16px;
        font-family: 'nunitoBold';
        color: #231965;
        font-size: 17px;
    }
    .item {
        display: flex;
        padding: 12px 16px;
        justify-content: space-between;
        border-bottom: 1px solid #f2f4f4;
        .left-section {
            display: flex;
            align-items: center;
            .icon-block {
                background: #F8F8F8;
                width: 32px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
            }
            .item-value {
                font-size: 17px;
                color: #000;
                // font-family: 'nunitoSemiBold';
                margin-left: 13px;
            }
        }
        .arrow {
            width: 9px;
            opacity: .6;
        }
    }
}



.back-title-block {
    display: flex;
    align-items: center;
    // padding: 10px 8px 24px 8px;
    padding: 15px 8px 15px 8px;
    .back-button {
        font-size: 24px;
    }
    span {
        margin-left: 8px;
        font-size: 20px;
        // font-family: 'nunitoRegular';
        // font-size: 24px;
        font-family: nunitoBold;
    }
}

.calendar-date-block-section {
    color: #000 !important;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    .calender-heading {
        color: #F37921 !important;
        font-size: 14px;
        text-align: left;
    }
    .filed-head{
        position: relative;
        margin-bottom: 10px;
        .ion-input {
            height: 18px;
            font-size: 12px !important;
            padding: 0;
            color:#000000;
            padding-left: 8px; 
            border-width: 0 0 1px 0;
            border-color:  #cbcbcb;
            background-color: #F2F4F4;
            min-width: 75px;
            margin-left: 5px;
            //text-align: center;
            width: 75px;
            max-width: 75px;
            &:focus-visible{
               outline:none; 
            }
            &:disabled{
                cursor:not-allowed;
            }
        }
        ion-label {
            font-size: 12px !important;
            margin: 0;
            width: 58px;
            // text-align: right;
            position: relative;
          
        }
        th:first-child, td:first-child{
            width:20% !important;
            text-align: center !important;
        }
    }
  
    
}

@media screen and (max-width: 500px) {
    
    .calendar-date-block-section {
        .calender-heading {
            color: #F37921 !important;
            font-size: 14px;
            text-align: left;
        }
        .filed-head{
            display:flex;
            flex-direction: row;
            .ion-input {
                height: 18px;
                font-size: 12px !important;
                padding: 0;
                color:#000000;
                padding-left: 8px; 
               
                border-width: 0 0 1px 0;
                border-color:  #cbcbcb;
                background-color: #F2F4F4;
                margin-left: 5px;
                margin-right: 12px; 
             

                //text-align: center;
                // width: unset !important;
                // max-width: unset !important;
                &:focus-visible{
                   outline:none; 
                }
                &:disabled{
                    cursor:not-allowed;
                }
            }
            ion-label {
                font-size: 11px !important;
                margin: 0;
                width: 62px;
                // text-align: right;
                position: relative;
              
            }
            th:first-child, td:first-child{
                width:20% !important;
                text-align: center !important;
            }
        }
    }

}

ion-tab-bar {
    box-shadow: 0 -3px 10px #00000029;
}

.submit-button{
    --border-radius: 3px;
    border-radius: 3px !important;
    height: 45px;
    width: 216px;
    --background: #F37921;
    --background-activated: #F37921;
    --background-focused: #F37921;
    color: #fff;
    box-shadow: 0px 2px 12px #F37921AD;
    border-color: #f37921;
    border-bottom: none;
}

.loader {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 30px;
    }
}

.coming-soon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.select-search-block{
    // width: calc(100vw - 8px);
    background: url('assets/svg/mobile-header-bg-bigger.svg');
    // background-size: 100%;
    background-size: cover;
    border: 1px solid #593cc7;
    width: 100%;
    .select-block{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.select-search-block-no-graph {
    background: #231965;
    padding: 4px 0 8px;
    .title-block-custom {
        background: #231965;  
        padding: 8px 16px 1px;
        height: unset;
        .icons {
            .first-icon {
                margin-right: 8px;
            }
            ion-icon {
                font-size: 18px;
            }
        }
    }
    .value {
        margin-left: 48px;
        font-size: 20px;
        font-family: 'nunitoExtraBold';
    }
}

.search-block{
    .header-icon-block {
        .iconsize {
            height: 18px;
            width: 23px;
            padding: 0px;
        }
        .noti-icon {
            margin-right: 10px !important;
        }
    }
    
}

.tabs-content{
    min-height: 100% !important;
    swiper-container{
        // height: 100vh;
        // min-height: 100% !important;
        swiper-slide{
            // min-height: calc(100vh - 280px);
            min-height: calc(100vh - 325px);
            align-items: flex-start;
        }
        .emptyData{
            height: calc(100vh - 260px);
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.full-width{
    width: 100%;
}

.segment-block {
    display: flex;
    align-items: center;
    .desktop-badge {
        // background: #fff !important;
        --padding-start: 3px !important;
    }
    ion-badge {
        margin-left: 3px;
        background: #F37921;
        border-radius: 100%;
        width: 20px;
        height: 20px;
        color: #f9bc90;
        --padding-start:1px;
        --padding-end:0;
        --padding-bottom:0;
        --padding-top:0;
        display: flex;
        justify-content: center;
        align-items: center;
        // position: absolute;
        // right: -24px;
    }
}



.header-upper-top{
    ion-select{
        // font-size: 24px;
        font-size: var(--ion-largest-font-size);
        font-family: nunitoBold;
        padding-top: 0;
    }
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.p-l-5 {
    padding-left: 5px !important;
}

.p-l-10 {
    padding-left: 10px !important;
}

.p-r-10 {
    padding-right: 10px !important;
}

.p-r-inherit {
    padding-right: inherit !important;
}

.m-b-0{
    margin-bottom: 0 !important;
}

.m-t-0{
    margin-top: 0px !important;
}

.m-t-2{
    margin-top: 2px !important;
}

.m-t-6{
    margin-top: 6px !important;
}

.m-t-8{
    margin-top: 8px !important;
}

.m-t-12 {
    margin-top: 12px !important;
}

.m-t-16{
    margin-top: 16px !important;
}

.m-t-20{
    margin-top: 20px !important;
}

.m-t-30{
    margin-top: 30px !important;
}

.m-t-43{
    margin-top: 43px !important;
}

.m-b-4{
    margin-bottom: 4px !important;
}

.m-b-8{
    margin-bottom: 8px !important;
}

.m-b-10{
    margin-bottom: 10px !important;
}

.m-b-16{
    margin-bottom: 16px !important;
}

.m-r-0{
    margin-right: 0px !important;
}

.m-r-5{
    margin-right: 5px !important;
}

.m-r-15{
    margin-right: 15px !important;
}

.m-r-16{
    margin-right: 16px !important;
}

.m-r-6{
    margin-right: 6px !important;
}

.m-pt0{
    padding-top:0 !important;
    margin:0 !important;
}
.m-b-2{
    margin-bottom: 2px !important;
}
.m-b-20{
    margin-bottom: 20px !important;
}
.m-b-30{
    margin-bottom: 30px !important;
}
.m-l-2-per {
    margin-left: 2% !important;
}
.m-l-0 {
    margin-left: 0px !important;
}
.m-l-2 {
    margin-left: 2px !important;
}
.m-l-3 {
    margin-left: 3px !important;
}
.m-l-16{
    margin-left: 16px !important;
}
.m-l-20 {
    margin-left: 20px !important;
}
.m-v-12 {
    margin: 12px 0;
}
.b-l-0 {
    border-left: 0 !important;
}
.b-t-0 {
    border-top: 0 !important;
}
.b-b-cb {
    border-bottom: 1px solid #cbcbcb !important;;
}
.v-p-5 {
    padding-bottom: 5px !important;
    padding-top: 5px !important;
}
.v-p-6 {
    padding-bottom: 6px !important;
    padding-top: 6px !important;
}
.width-8 {
    width: 8px !important;
}
.width-10 {
    width: 10% !important;
}
.width-14 {
    width: 14px !important;
}
.width-100 {
    width: 100% !important;
}
.width-50 {
    width: 50% !important;
}
.width-60 {
    width: 60% !important;
}
.-min-height-0 {
    --min-height:0
}
.-min-height-auto {
    --min-height:auto
}
.height-16 {
    height: 16px !important;
}
.height-17 {
    height: 17px !important;
}
.height-19 {
    height: 19px !important;
}
.height-20 {
    height: 20px !important;
}
.height-30 {
    height: 30px !important;
}
.height-150 {
    height: 150px !important;
}
.height-50 {
    height: 50px !important;
}
.height-350 {
    height: 350px !important;
}
.min-height-275 {
    min-height: 275px !important;
}
ion-skeleton-text {
    width: 60%;
}
.font-12 {
    font-size: 12px !important;
}
.font-13 {
    font-size: 13px !important;
}
.font-14 {
    font-size: 14px !important;
}
.text-right{
    text-align: right;
}
.text-left{
    text-align: left;
}

.hide {
    display: none !important;
}
.visibility-hidden {
    visibility: hidden !important;
}

.filter-radio-group {
    ion-item {
        border-bottom:1px solid #DFE0E3;
        ion-radio {
            margin-right: 12px;
            --color-checked: #f37921;
            --color: silver;
        }
    }
}

.profile-circle {
    width: 23px;
    height: 23px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    ion-icon {
        color: #231965;
    }
}

.client-not-traded-select {
    .ng-arrow-wrapper {
        background: #DFE0E3;
        height: 35px;
        .ng-arrow {
            top: 8px !important;
            left: 3px !important;
        }
    }
    
    .ng-select-container {
        padding-left: 10px;
        font-size: 15px;
    }
    .ng-dropdown-panel {
        .ng-dropdown-panel-items {
            .ng-option {
                padding: 0 !important;
                border-bottom: 1px solid silver !important;
                ion-item {
                    ion-label {
                        font-size: 14px;
                        color: #282828;
                        font-family: 'nunitoRegular';
                    }
                    ion-radio {
                        margin-right: 12px;
                        --color-checked: #f37921;
                        --color: silver;
                    }
                }
            }
        }
    }
}

.client-not-traded-select.ng-select-opened > .ng-select-container .ng-arrow {
    top: 4px !important;
}


.revamp-more-option {
    display: flex;
    flex-direction: column;
    height: 764px;
    flex-wrap: wrap;
    margin-bottom: 16px;
    ion-card {
        width: 31%;
        border: 1px solid #CBCBCB;
        ion-card-header {
            padding-bottom: 4px;
            display: flex;
            flex-direction: row;
            align-items: center;
            img {
                width: 30px;
            }
            ion-card-title {
                font-size: 18px;
                font-family: 'nunitoSemiBold';
                margin-left: 8px;
                margin-top: 0;
                color: #000;
            }
        }
        ion-card-content {
            padding-bottom: 5px;
            ion-item {
                --padding-start: 0;
                --inner-padding-end: 0;
                ion-icon {
                    width: 5px;
                }
                ion-label {
                    font-size: 14px !important;
                    color: #212121 !important;
                    font-family: 'nunitoSemiBold' !important;
                }
                &:last-child {
                    --border-color: transparent;
                    
                }
            }
        }
    }
}

.revamp-drop-down {
    padding-top: 20px;
    .business-monitor-block {
        display: flex;
        justify-content: space-between;
        .updated-data{
            // font-size: 15px;
            font-size: 12px;
            color: #6E6E6E;
            margin-bottom: 6px;
            display: block;
        }
        .business {
            color: #212121;
            font-size: 24px;
            font-family: 'nunitoBold';
            width: 36px;
            height: 36px;
            background: #80808063;
            border-radius: 8px;
            margin-right: 8px;
            margin-top: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .select-block {
            display: flex;
            border: 1px solid #00083933;
            border-radius: 3px;
            // width: 250px;
            padding: 2px 10px;
            align-items: center;
            border: none;
            // width: unset;
            width: 340px;
            padding: 0;
            .value-section {
                display: flex;
                flex-direction: column;
                width: 100%;
                .name {
                    // font-family: 'nunitoBold';
                    // font-size: 12px;
                    color: #000000;
                    font-size: 16px;
                    font-family: 'nunitoSemiBold';
                }
                .account-type {
                    // color: #9C9C9C;
                    // font-size: 10px;
                    font-size: 12px;
                    color: #6E6E6E;
                }
            }
        }
    }
    
    .select-parent {
        position: relative;
        z-index: 999;
        border-bottom: 1px solid #DFE0E3;
        padding-bottom: 4px;
        // cursor: pointer;
    }
    
    .drop-down-icon {
        width: 16px;
    }
    
    .select-drop-down {
        z-index: 999;
        top: 5px;
        // box-shadow: 0px 4px 20px #00000029;
        // min-height: 100px;
        padding: 12px 8px;
        background: #fff;
        border-radius: 4px;
        // cursor: default;
        z-index: 999;
        width: 99%;
        padding: 0.5px 0 0 8px;
        background: #fff;
        border-radius: 4px;
        padding-right: 0;
        cursor: pointer;
        top: 0px;
        position: relative;
        .parent-check-block {
            // max-height: calc(100vh - 325px);
            max-height: calc(100vh - 360px);
            overflow-y: auto;
        }
        ion-checkbox {
            --background: transparent;
            --background-checked: transparent;
            --border-color: #cbcbcb;
            --border-color-checked: #cbcbcb;
            --checkmark-color: #F37A21;
            --size: 12px;
        }
        .top-check-block {
            display: flex;
            // margin-bottom: 20px;
            padding: 6px 0;
            // margin-right: 4px;
            img {
                width: 6px !important;
                align-self: baseline;
                position: relative;
                top: 3px;
            }
        }
        .name-section {
            // margin-left: 8px;
            width: 86%;
            .name {
                font-size: 12px;
                // font-family: 'nunitoBold';
                font-family: 'nunitoSemiBold';
                color: #000000;
                line-height: 12px;
            }
            .type {
                font-size: 10px;
                // color: #9C9C9C;
                color: #6E6E6E;
                margin-top: 2px;
            }
        }
        .inner-check-block {
            display: flex;
            padding: 8px;
            align-items: flex-start;
            justify-content: space-between;
            position: relative;
            padding-left: 16px;
        }
        .lower-check-block {
            margin-top: 8px;
        }
        .inner-check-block-child {
            padding-left: 16px;
            position: relative;
        }
        // .inner-check-block-child .inner-check-block::before {
        //     content: '';
        //     width: 1px;
        //     height: calc(100% - 10px);
        //     background: #cbcbcb;
        //     position: absolute;
        //     left: 22px;
        //     z-index: -7;
        //     top: 19px;
        // }
        .inner-check-block-child .inner-check-block:last-child:before {
            height: 0;
        }
        .inner-check-block-parent {
            position: relative;
        }
        .inner-check-block-parent::before {
            content: '';
            width: 1px;
            height: calc(100% - 10px);
            background: #cbcbcb;
            position: absolute;
            left: 22px;
            z-index: -7;
            top: 19px;
        }
        .inner-check-block-parent:last-child:before {
            height: 0;
        }
        .inner-check-block-child:before {
            // content: "";
            width: 1px;
            height: calc(100% - 10px);
            background: #cbcbcb;
            position: absolute;
            left: 37px;
            z-index: -7;
            top: 19px;
        }
        .third-hierarchy .inner-check-block-child:last-child::before, 
        .fourth-hierarchy .inner-check-block-child:last-child::before,
        .fifth-hierarchy .inner-check-block-child:last-child::before {
            height: 0;
        }
        // .inner-check-block-child:before {
        //     height: 0;
        // }
    }

    .title-block-custom .icon-title {
        display: flex;
        align-items: center;
        width: auto;
        .title{
            margin-right: 0 !important;
        }
        .arrow-down-white {
            width: 10px;
        }
    }
    .hierarchy-wrapper {
        // padding-top: 8px;
        height: 0;
        box-shadow: 0px 4px 20px #00000029;
        transition: all 0.5s ease;
        position: absolute;
        width: 100%;
        background: #fff;
        overflow: hidden;
            
    }
    .hierarchy-wrapper > div{
        // max-height: calc(100vh - 405px);
        max-height: calc(100vh - 420px);
        overflow: auto;
        background: #fff;
    }
    .add-hierarchy-height {
        height: calc(100vh - 286px);
        max-height: calc(100vh - 286px);
        padding-top: 8px;
        padding: 14px 10px 0 8px;
    }
    .apply-btn {
        // top: calc(100vh - 342px);
        position: relative;
        padding: 22px 0 18px;
        ion-button {
            width: 104px;
            margin: auto;
            border-radius: 20px;
            height: 32px;
            --border-radius: 20px;
            font-size: 12px;
        }
    }
}


// reavamp dashboard component style
.common-header-all {
    background: #F7F7F7;
    height: 300px;
    position: relative;
    top: -30px;
    padding-top: 16px;
    .back-drop-down-section {
        display: flex;
        justify-content: space-between;
        .back-section {
            display: flex;
            align-items: center;
            margin: 24px 0;
            .back-icon {
                cursor: pointer;
                ion-icon {
                    width: 24px;
                    position: relative;
                    top: 2px;
                }
            }
            .type-section {
                font-size: 24px;
                color: #212121;
                margin-left: 18px;
                font-family: 'nunitoSemiBold';
                display: flex;
                align-items: center;
                font-size: 28px;
                .value {
                    font-family: 'nunitoExtraBold';
                    margin-left: 8px;
                }
            }
        }
    }
    
}

#jsModal {
    position: absolute;
    left: -375px;
    top: -92px;
    .modal__container {
        width: 372px;
    }
}

.dashbaord-container {
    position: relative;
    top: -80px;
    top: unset;
}
.common-content-section {
    .card-type-segment {
        margin-bottom: 12px;
        display: flex;
        ion-segment-button {
            --indicator-height: 0;
            flex: 0 1 auto;
            min-width: 211px;
            --background-hover: transparent;
            .card {
                display: flex;
                flex-direction: column;
                padding: 20px;
                font-size: 13.5px;
                color: #6E6E6E;
                border: 1px solid #DFE0E3;
                position: relative;
                margin-bottom: 15px;
                overflow: visible;
                text-transform: none;
                border-radius: 12px;
                box-shadow: none;
                width: 100%;
                margin-top: 0;
                .value {
                    font-family: 'nunitoBold';
                    color: #212121;
                    margin-top: 6px;
                    .percent {
                        font-family: 'nunitoRegular';
                        color: #83838B;
                    }
                }
                .indicator {
                    display: none;
                }
            }
        }
        .segment-button-checked {
            .card {
                color: #797EAC;
                border: 1px solid #7564DD;
                box-shadow: 0px 5px 20px #593cc71a;
                background: #F7F7FF;
                font-family: 'nunitoSemiBold';
                .value {
                    .percent {
                        color: #212121;
                    }
                }
                .indicator {
                    display: block;
                    position: absolute;
                    bottom: -16px;
                    left: 45%;
                }
            }
        }
    }

}

.segment-search-block {
    display: flex;
    align-items: center;
    margin: 16px 0;
    
    .header-segment-top {
        justify-content: flex-start;
        ion-segment-button {
            max-width: 120px;
            --color: #586A7A;
            --color-checked: #fff;
        }
    }
    ion-searchbar {
        width: 400px;
        height: 30px;
        .searchbar-input {
            padding-right: 4px;
            padding-left: 15px;
        }
    }
}

.input-search-padding {
    ion-searchbar {
        .searchbar-input {
            padding-right: 34px !important;
            padding-left: 36px !important;
        }
    }
}



.header-segment-with-border-parent {
    justify-content: space-between;
    .custom-searchbar {
        margin-top: 6px;
    }
    .header-segment-with-border {
        // border: 1px solid #888;
        width: 328px;
        border-radius: 6px;
        ion-segment-button {
            border: 1px solid #888;
            --indicator-height: 0;
            --color-checked: #F37921;
            text-transform: capitalize;
            // width: 47%;
            height: 34px;
            min-height: 34px;
            .checkmar-img {
                display: none;
            }
        }
        .segment-button-checked {
            border: 1px solid #F37921;
            border-radius: 6px;
            .checkmar-img {
                display: inline-block;
                margin-right: 8px;
            }
        }
        .first {
            border-radius: 6px 0 0 6px;
            border-right: none;
        }
        .second {
            border-radius: 0 6px 6px 0;
            border-left: none;
        }
        .first.segment-button-checked {
            border-right: 1px solid;
        }
        .second.segment-button-checked {
            border-left: 1px solid;
        }
    }
    .search-filter-parent {
        display: flex;
        img {
            margin-left: 46px;
        }
    }
    .search-filter-parent-mobile {
        display: flex;
        width: calc(100vw - 32px);
        margin-top: 8px;
        img {
            width: 16px;
            margin-left: 16px;
        }
    }
}



.first-segment {
    // width: 106px;
    // text-align: left;
    // padding-right: 12px;
}

.tab-button-selected{
    background: #F6F6F5;
    color: #593CC7;
    font-family: nunitoExtraBold;
    border-top: 3px solid #593CC7;
}

.mobile-header-table{
    thead{
        background: #f2f4f4 !important;
        color: #c0c1c1 !important;
        th{
            padding: 10px;
            font-size: var(--ion-smallest-font-size);
            font-family: nunitoRegular;
            color: var(--ion-table-heading-color);
        }
    }
}



.justify-content-center{
    justify-content: center !important;
}

.justify-content-end{
    justify-content: flex-end !important;
}

.justify-content-start{
    justify-content: flex-start !important;
}

.mobile-header {
    // --background: #231965;
    --background: url('./assets/svg/Header.svg'), url('./assets/svg/Header_graph.svg');
    // --background: url('./assets/svg/Header_graph.svg');
    --background: url('./assets/svg/mobile-header-bg-bigger.svg');
    color: #fff;
}

.mobile-table{
    .inner-tr-style-block{
        display: flex;
    }
    td{
        font-size: 13px;
    }
}


.td-heading {
    display: flex;
    flex-direction: column;
    height: 50px;
    justify-content: center;
}

.td-heading span:nth-child(2) {
    font-family: nunitoLight;
}

.first-column{
    // flex-direction: row;
    // justify-content: flex-start;
    justify-content: center;
    align-items: left;
    // padding-top: 8px;
    .first-span {
        // text-transform: capitalize !important;
        color: #000;
        font-family: nunitoBold;
        font-size: var(--ion-small-font-size);
    }
}

.first-column span:nth-child(2) {
    font-family: nunitoRegular;
}



// snapshot table 
.desktop-table-snapshot {
    table {
        td:first-child {
            max-width: 1vw;
        }
    }
}

.desktop-table-snapshot-bulk {
    table {
        td {
            color: #212121;
            max-width: .5vw;
        }
        td:first-child {
            max-width: .8vw;
            font-family: nunitoBold;
            color: #000;
        }
    }
}

.snapshot-modal-parent {
    margin-top: 33px;
    overflow: auto;
    width: 95.5% !important;
    border-radius: 10px;
    margin-left: 4%;
    max-height: 68vh;
    .gainerstable {
        width: 566.4px;
        width: 636px;;
        border-radius: 0 0 10px 10px !important;
    }
    thead {
        position: absolute;
        // width: 283.2px;
        width: 316.8px;
        top: 73px;
    }
    th, td {
        padding: 10px !important;
        // width: 76.464px !important;
        // max-width: 76.464px !important;
        width: 93px !important;
        max-width: 93px !important;
    }
    th {
        color: #6E6E6E !important;
        text-align: right !important;
    }
    td {
        color: #212121 !important;
        text-align: right !important;
    }
    td:first-child {
        text-align: left !important;
        font-family: nunitoBold !important;
        color: #000 !important;
        max-width: 100px;
        width: 130.272px !important;
        max-width: 130.272px !important;;
    }
    th:first-child {
        text-align: left !important;
        width: 130.272px !important;
        max-width: 130.272px !important;
    }
}

.gainer-modal-parent {
    th, td {
        padding: 10px !important;
        // width: 99.225px !important;
        // max-width: 99.225px !important;
        width: 111.2px !important;
        max-width: 111.2px !important;
    }
    td:first-child {
        text-align: left !important;
        font-family: nunitoBold !important;
        color: #000 !important;
        // width: 184.275px !important;
        // max-width: 184.275px !important;
        width: 205px !important;
        max-width: 205px !important;
    }
    th:first-child {
        text-align: left !important;
        // width: 184.275px !important;
        // max-width: 184.275px !important;
        width: 205px !important;
        max-width: 205px !important;
    }
}

.week-modal {
    th, td {
        padding: 10px !important;
        // max-width: 76.464px !important;
        // max-width: 76.464px !important;
    }
    td:first-child {
        // width: 130.272pxpx !important;
        // max-width: 130.272pxpx !important;
    }
    th:first-child {
        // width: 125.272pxpx !important;
        // max-width: 125.272pxpx !important;
        // width: 142.4px !important;
        // max-width: 142.4px !important;
    }
    td:nth-child(2) {
        // width: 81.464px !important;
        // max-width: 81.464px !important;
        // width: 88.8px !important;
        // max-width: 88.8px !important;
        // padding: 10px 0 !important;
    }
    th:nth-child(3) {
        // width: 85.6px !important;
        // max-width: 85.6px !important;
        // padding: 10px 0 !important;
    }
}
.modal-topper-parent {
    th, td {
        text-align: right !important;
    }
    td:first-child {
        max-width: 150px;
        text-align: left !important;
    }
    th:first-child {
        text-align: left !important;
    }
}

.desktop-table-snapshot-topper {
    table {
        th, td {
            text-align: right;
            font-size: 14px;
        }
        th {
            color: #6E6E6E !important;
        }
        td {
            color: #212121;
        }
    }
}

.black-color {
    color: #000 !important;
}

.no-data-found-parent {
    .cursor-auto {
        .no-data-found {
            font-family: nunitoRegular !important; 
            text-align: center !important; 
            color: #212121 !important;
        }
    }
}

.ccc-color {
    color: #9C9C9C !important;
}

.eb-color {
    color: #2680EB !important;
}

.font-green {
    color: #17C257 !important;
}

.font-small {
    font-size: small !important;
}

img.info {
    position: absolute;
    right: 4px;
    top: 5px;
}

.font-black {
    color: #000 !important;
}

.loser-colors {
    color: #F55858 !important;
}

.title-header-looser {
    color: #B92121 !important;
}

.fcc-bg {
    background: #FCCCCC !important;
}

.d5e-bg {
    background: #D5EFCC !important;
}

.f2f-bg {
    background: #F2F4F4 !important;
}

// indices 
.indices-heading {
    color: #000000;
    font-size: 18px;
}

.indices {
    display: flex;
    justify-content: space-between;
    .block {
        width: 22%;
    }
    .web-equity {
        .header-table {
            th:first-child {
                padding-left: 16px !important;
            }
            th:last-child {
                padding-right: 16px;
            }
        }
        .main-table {
            // max-height: calc(100vh - 282px);
            //harshal
            max-height: calc(100vh - 410px);
            overflow-y: scroll;
            overflow-x: hidden;
            td {
                
                .scrip {
                    color: #000000;
                    font-size: 14px;
                    font-family: 'nunitoBold';
                }
                .date {
                    font-family: 'nunitoLight';
                    font-size: 11px;
                }
                .value-block {
                    .upper {
                        font-size: 13px;
                    }
                    .lower {
                        font-size: 11px;
                        font-family: 'nunitoLight';
                    }
                }
            }
            td:first-child {
                padding-left: 16px !important;
                max-width: 11vw !important;
                width: 55% !important;
            }
            td:last-child {
                padding-right: 16px;
                max-width: 8vw !important;
                width: 45% !important;
            }
            .no-data-found {
                text-align: center !important;
            }
        }
    }
}

//test 
.my-custom-class {
    #alert-1-msg {
        font-style: italic;
    }
  
}


// custom select
.custom-select {
    position: relative;
    padding-right: 10px;
    .datetitle {
        font-size: 12px;
        margin-bottom: 5px !important;
        color: #212121;
        font-family: 'nunitoSemiBold';
        text-align: left;
    }
    ion-select {
        justify-content: center;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-left: 0;
        padding-right: 0;
        // padding-right: 20px;
        // &::part(icon) {
        //     display:none;
        // }
        &::part(text) {
            color: #757382;
            font-size: 16px;
            font-family: 'nunitoSemiBold';
            // margin-left: -12px;
        }
        &::part(icon) {
            width: 19px;
        }
    }
    // .downiconalingment{
    //     position: absolute;
    //     bottom: 4px;
    //     right: 13px;
    //     font-size: 13px;
    // }
    // .select-icon{
    //     display: none;
    //  }
}

.mega-menu {
    box-shadow: 0px 3px 6px #00000029;
    position: absolute;
    background: #fff;
    top: 60px;
    width: 100vw;
    height: 0px;
    overflow: hidden;
    // padding-bottom: 0;
    -webkit-transition: height .5s ease;  
    -moz-transition: height .5s ease;  
    -o-transition: height .5s ease;  
    -ms-transition: height .5s ease;  
    transition: height .5s ease;
    // padding-bottom: 30px;
    .more-option-block {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        padding: 17px 100px 17px 150px;
        // padding: 50px 100px 50px 150px;
        font-size: 14px;
        color: #212121;
        .item {
            padding: 8px 0;
            // cursor: pointer;
            color: '#212121';
            font-size: 13px;
            font-family: 'nunitoSemiBold';
        }
    }
    .more-option-block.more-option-six-block{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        padding: 17px 50px 17px 50px;  
    }

}

.vertical-mega-menu{
    display: grid;
    grid-template-columns: 20% 70% ;
    padding: 17px 100px 17px 150px;
    gap: 0; 
    .tab-item{
        font-family: nunitoSemiBold;
        font-size: 15px;
        color: #797979;
        margin: 5px 0;
        height: 28px;
        position: relative;
        cursor: pointer;
        &::after{
            border: solid #797979;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 3px;
            content: '';
            display: block;
            position: absolute;
            right: 28%;
            top:6px;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
        }
      
    }
    .active{
        color: #2D197F;
        &::after{
            border-color:#2D197F ;
        }
     }
    .tab-content{
        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            a{
                font-size: 12px;
                cursor: pointer;
                color: #757382;
                font-family: "nunitoRegular";
                margin: 5px 0; 
                text-decoration: none;
            }
            .inner-title{
                color: #212121;
                font-size: 13px;
                font-family: "nunitoSemiBold";
                }
            
        }
        .main-Summaries, .main-Others{
           ul{display: grid;
            grid-template-columns: 1fr 1fr 1fr;} 
        }
        .main-Individual{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }    
    } 
}

.mega-menu-invest {
    .more-option-block {
        // padding: 17px 100px 17px 0;
        .item {
            .item-block {
                display: flex;
                flex-direction: column;
                .item-heading:focus {
                    outline: none;
                }
                .inner-item {
                    font-size: 12px;
                    cursor: pointer;
                    color: #757382;
                    font-family: 'nunitoRegular';
                    margin: 3px 0;
                }
            }
        }
    }
}

.mega-menu-clients {
    .more-option-block {
        padding: 17px 115px 17px 150px;
        display: flex;
        justify-content: space-between;
    }
}

.Search-Icon {
    height: 18px;
    width: 23px;
}

.outline-button {
    --background: transparent !important;
    border: 1px solid #F37921 !important;
    --color: #F37921 !important;
    font-size: 12px;
    font-family: nunitoSemiBold;
    border-radius: 20px !important;
    --box-shadow: none !important;
    height: 30px !important;
    --background-activated: transparent !important;
    --background-focused: transparent !important;
    --background-hover: transparent !important;
}

.clientDetail {
    background: #fff !important;
    padding: 0 !important;
    height: auto !important;
    color: #341793 !important;
    font-size: 14px !important;
    font-weight: normal !important;
    align-items: center !important;
    ion-icon {
        margin-right: 3px;
    }
}

// create custom arrow with css
.arrow-up {
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #716A90;
}

.arrow-down {
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #F37921;
  }

.client-details {
    display: flex;
}

.disabled {
    opacity: .3;
    // pointer-events: none !important;
    cursor: not-allowed !important;
}

.z-index-0 {
    z-index: 0 !important;
}

.download-report {
    img {
        width: 16px;
        position: relative;
        right: 2px;
        top: 3px;
    }
}

.search-section {
    .downloads-section {
        display: flex;
        img {
            width: 16px;
        }
        span {
            flex: none;
        }
    }
}

// wathlist popup for mobile
// css changed for modal-wrapper	
ion-modal.edit-popup-mobile::part(content) { 
    margin: 0 16px;
    max-width: 400px;
    // border-radius: 10px;
    --background: transparent;
}
ion-modal.edit-popup-mobile{
    .ion-page{
        height: 315px;
        top: calc(100% - 315px);
        background: #fff;
        border-radius: 10px 10px 0 0;
        padding: 16px;
        background: #fff;
        ion-list{
            margin-top: 30px;
        }
        .close {
            position: absolute;
            right: 16px;
            top: 5px;
            color: #C1C4CC;
            font-family: nunitoExtraBold;
            font-size: 23px;
            cursor: pointer;
        }
        .title {
            display: flex;
            justify-content: center;
            color: #000;
            font-family: nunitoExtraBold;
            span {
                border-bottom: 3px solid var(--ion-red-secondary-color);
                font-size: 16px;
            }
        }
        .active{
            --background:#EEEEEE !important;
            color: #000 !important;
            font-family: nunitoBold;
            border: none;
        }
        .digit{
            font-family: nunitoBold;
            color: #000;
        }
        ion-button{
            width: 100%;
            color: #212121;
            font-size: 14px;
            font-family: nunitoSemiBold;
            --background: transparent;
            --box-shadow: none;
            --background-activated: transparent;
            --background-hover: transparent;
            --background-focused: transparent;
            --border-radius: 6px;
            height: 54px;
            border-bottom: 1px solid #EEEEEE;
            .item-block{
                display: flex;
                justify-content: space-between;
                width: 100%;
                position: relative;
                top: -2px;
            }
        }
        
    }
}

// snapshot view model
// css changed for modal-wrapper	
ion-modal.view-all-popup::part(content) { 
    // height: 50vh;
    margin: 0 16px;
    max-width: 400px;
    // border-radius: 10px;
    --background: transparent;
}
ion-modal.view-all-popup{
    .ion-page{
        min-height: 315px;
        height: auto;
        top: unset;
        background: #fff;
        border-radius: 10px 10px 0 0;
        padding: 16px;
        background: #fff;
        .header {
            display: flex;
            justify-content: flex-end;
            margin-right: 10px;
            .cross {
                top: -8px;
                position: relative;
                font-family: nunitoBold;
                color: #C1C4CC;
                right: -8px;
            }
        }
        table {
            width: 100%;
            tr {
                border: 1px solid #CBCBCB;
                border-top: none;
            }
            th {
                background: #D5EFCC;
                padding: 10px 0;
            }
            td {
                padding: 15px 0;
                width: 33.33%;
                max-width: 33vw;
            }
            .fontboldblack {
                color: #000000;
                font-family: "nunitoBold";
                font-size: 15px;
            }
            .leftalignwithpadding{
                text-align: left;
                padding-left: 15px !important;
            }
            .rightalignwithpadding {
                text-align: right;
                padding-right: 15px !important;
            }
        }
        .pr-15 {
            padding-right: 15px;
        }
        .weekhigh_title {
            display: flex;
            margin: 0 16px;
        }
        .modal-container {
            margin-top: 10px !important;
            overflow-y: auto;
            height: 100%;
            max-height: 78vh;
            .table-parent {
                width: 300px;
                margin: auto;
                width: auto;
                overflow-x: hidden;
            }
        }
        ion-list{
            margin-top: 30px;
        }
        .close {
            position: absolute;
            right: 16px;
            top: 5px;
            color: #C1C4CC;
            font-family: nunitoExtraBold;
            font-size: 23px;
            cursor: pointer;
        }
    }
}

// is agree popup
// css changed for modal-wrapper	
ion-modal.agree::part(content) {
    width: 80%;
    height: 90vh;
}

ion-modal.agree {
    iframe{
        width: 100%;
        height: 70vh;
    }
    border-radius: 8px;
    .ion-page {
        .parent-block {
            // padding: 12px 24px 40px;
            padding: 8px;
            font-size: 12px;
            font-family: nunitoLight;
            color: #A7A7A7;
            .text-block {
                // padding: 32px 16px;
                text-align: center;
            }
            .close {
                text-align: right;
                img {
                    width: 12px;
                }
            }
            .button-block {
                text-align: center;
                ion-button {
                    --border-radius: 3px;
                    border-radius: 3px !important;
                    height: 45px;
                    --background: #F37921;
                    --background-activated: #F37921;
                    --background-focused: #F37921;
                    color: #fff;
                    box-shadow: 0px 2px 15px #F37921AD;
                    border-color: #f37921;
                    border-bottom: none;
                    width: 216px;
                    margin-top: 35px;
                }
            }
            
        }
    }
}

#custom-modal-5 {
    // width: 1100px;
    .jw-modal {
        width: 1100px;
        .parent-table {
            width: 99%;
            overflow: auto;
            max-height: 65vh;
            padding: 0 !important;
            margin: 0;
            margin-top: 58px;
            margin-left: .5%
        }
        .toppers_list {
            border-radius: 0 0 10px 10px;
            border: 1px solid #cbcbcb;
            width: 1028px;
            margin-left: 30.5px;
            thead {
                background: none;
                position: absolute;
                top: 78px;
                width: 1028px
            }
            table {
                th, td {
                    width: 205.6px;
                    min-width: 115px;
                    max-width: 205.6px;
                }
            }
        }
    }
}

.no-wrap-table {
    td {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
    }
}

.overlay-modal{
    --background: #00000024 !important;
}

// date time 
.datetime-block {
    --background: transparent;
    --padding-start: 0;
    --padding-end: 0;
    // --min-height: 24px;
    --min-height: 18px;
    ion-label {
        font-size: 12px !important;
        margin: 0;

    }
    ion-datetime {
        font-size: 12px;
        padding: 0;
        /* padding-left: 8px; */
        // border: 1px solid #cbcbcb;
        border-bottom: 1px solid #cbcbcb;
        min-width: 75px;
        margin-left: 5px;
        text-align: center;
        width: 75px;
    }
}


#dvAAABanner #dvAAASilder{
    cursor: pointer;
}


// media query for mobile

@media only screen and (max-width: 420px) {
    .first-span {
      font-size: 13px !important;
    }
  }



// for desktop

.error-block .error-message{
    color: red;
}

// wathlist popup for desktop
// css changed for modal-wrapper	
ion-modal.edit-popup-desktop::part(content) { 
     // height: 50vh;
     margin: 0 16px;
     max-width: 350px;
     height: 330px;
     // border-radius: 10px;
     --background: transparent;
}
ion-modal.edit-popup-desktop{
    ion-backdrop{
        opacity: .8 !important;
    }
    .ion-page{
        // height: 315px;
        // top: unset;
        background: #fff;
        border-radius: 10px;
        padding: 16px;
        background: #fff;
        ion-list{
            margin-top: 30px;
        }
        .close {
            position: absolute;
            right: 16px;
            top: 5px;
            color: #C1C4CC;
            font-family: nunitoExtraBold;
            font-size: 23px;
            cursor: pointer;
        }
        .title {
            display: flex;
            justify-content: center;
            color: #000;
            font-family: nunitoExtraBold;
            span {
                border-bottom: 3px solid var(--ion-red-secondary-color);
                font-size: 16px;
            }
        }
        .active{
            --background:#EEEEEE !important;
            color: #000 !important;
            font-family: nunitoBold;
            border-bottom: none;
        }
        .digit{
            font-family: nunitoBold;
            color: #000;
        }
        ion-button{
            width: 100%;
            color: #212121;
            font-size: 14px;
            font-family: nunitoSemiBold;
            --background: transparent;
            --box-shadow: none;
            --background-activated: transparent;
            --background-hover: transparent;
            --background-focused: transparent;
            --border-radius: 6px;
            height: 50px;
            // border-bottom: 1px solid #EEEEEE;
            .item-block{
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
        }
        
    }
}


.ChatBot {
    position: absolute;
    right: 50px;
    bottom: 50px;
}

// mutual fund products more option popup
.mutual-fund-products-more {
    ion-item {
        ion-label {
            font-size: 16px !important;
            font-family: nunitoBold !important;
            color:#000 !important;
        }
    }
    .go-to-mutual {
        color: #2680EB;
        font-size: 18px;
        text-align: center;
        font-family: nunitoSemiBold;
        margin-top: 20px;
    }
}

.myDpSelectorAbsolute {
    margin-top: 21px !important;
    &:focus{
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none !important;
    }
}
.myDpDisabled{
    cursor:not-allowed !important;
    &:focus{
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none !important;
    }
}

.custom-popover {
    .profile-details {
        width: 100%;
        padding-top: 8px;
        .profile-name {
            font-size: 16px;
            color: #212121;
            font-family: 'nunitoSemiBold';
        }
        .clients-details {
            color: #6E6E6E;
            font-size: 14px;
            display: flex;
            align-items: center;
            margin-top: 2px;
            border-bottom: 1px solid #f4f4f4;
            padding-bottom: 12px;
            .dot {
                width: 4px;
                height: 4px;
                background: #9B9B9B;
                display: inline-block;
                margin: 0 10px;
                border-radius: 50%;
            }
        }
    }
    
}

.dropdown-popover {
    .popover-wrapper {
        .popover-content {
            border-radius: 12px;
            box-shadow: 0px 5px 20px rgba(36, 36, 36, 0.12);
            width: 154px !important;
            height: 94px !important;
        } 
    }
}   

.dropdown-popover-without-breakdown {
    .popover-wrapper {
        .popover-content {
            border-radius: 12px;
            box-shadow: 0px 5px 20px rgba(36, 36, 36, 0.12);
            width: 154px !important;
            height: 59px !important;
        } 
    }
}

// filter mutual fund holding portfolio popup
.custom-popover {
    .popover-wrapper {
        .popover-content {
            width: 200px;
            .popover-viewport {
                .filter-block {
                    ion-list {
                        ion-item {
                            height: 36px;
                            ion-checkbox {
                                margin: 6px 8px 6px 0;
                                --border-color-checked: #F37921;
                                --checkmark-color: #F37921;
                                --background-checked: transparent;
                            }
                        }
                    }
                    .button-section {
                        text-align: center;
                        margin-bottom: 20px;
                        margin-top: 10px;
                        ion-button {
                            border-radius: 50px;
                            --border-radius: 50px;
                            color: #633EE3;
                            background: transparent;
                            --background: transparent;
                            border: 1px solid #633EE3;
                            font-family: 'nunitoBold';
                        }
                    }
                }
            }
        }
    }
}
ion-modal.edit-ip-details::part(content) { 
    max-width: 510px;  
    height: 480px !important;
    border-radius: 8px;
    position: relative;
}
// superstars popup
ion-modal.superstars::part(content) { 
    max-width: 510px;  
    height: 410px;
    border-radius: 8px;
    position: relative;
}
ion-modal.superstars {
    ion-backdrop {
        opacity: .7 !important;
    }
    .ion-page {
        padding: 30px 30px 35px;
        .header-table {
            width: 100%;
            // background: #F2F4F4;
            margin-top: 20px;
            thead {
                background: #f2f4f2;
                th {
                    text-align: right;
                    padding: 12px;
                    color: #6E6E6E;
                    font-size: 12px;
                }
                th:first-child {
                    border-radius: 4px 0 0 4px;
                    text-align: left;
                    width: 47%;
                    padding-left: 16px;
                }
                th:nth-child(2) {
                    width: 20%;
                    padding: 12px 6px;
                }
                th:last-child {
                    border-radius: 0 4px 4px 0;
                    width: 33%;
                    padding-right: 16px;
                }
            }
        }
        .table-wrapper {
            height: 209px;
            overflow: auto;
        }
        .body-table {
            width: 100%;
            tr {
                border-bottom: 1px solid #CBCBCB;
                td {
                    text-align: right;
                    padding: 12px;
                    color: #000000;
                    font-family: nunitoSemiBold;
                    font-size: 14px;
                    width: 30%;
                }
                td:first-child {
                    width: 40%;
                    text-align: left;
                    max-width: 100px;
                    padding-left: 16px;
                }
                td:nth-child(2) {
                    // width: 20%;
                    padding: 12px 6px;
                    text-align: center;
                }
                td:last-child {
                    // width: 33%;
                    padding-right: 16px;
                    padding-left: 0;
                    max-width: 25vw;
                    white-space: nowrap;
                    text-overflow: ellipsis !important;
                    overflow: hidden;
                    text-align: right;
                }
            }
            tr:last-child {
                border: none;
            }
        }
        .view-more {
            font-size: 14px;
            text-align: center;
            color: #2680EB;
            margin-top: 30px;
            cursor: pointer;
            font-family: nunitoSemiBold;
        }
        .close {
            position: absolute;
            right: -16px;
            top: -30px;
            color: #C1C4CC;
            font-family: nunitoExtraBold;
            font-size: 23px;
            cursor: pointer;
        }
        .title {
            display: flex;
            justify-content: center;
            color: #000;
            font-family: nunitoExtraBold;
            font-size: 16px;
            span {
                border-bottom: 3px solid var(--ion-red-secondary-color);
                padding: 0 5px 2px;
            }
        }
    }
}

ion-modal.score::part(content) { 
// .score {
//     .modal-wrapper {
        max-width: 832px;  
        width: 832px;
        height: 355px;
        border-radius: 8px;
}
ion-modal.score{
        .ion-page {
            padding: 30px 3px;
            .close {
                right: 10px;
            }
            .current-score {
                border: 1px solid #F37921;
                background: #F379210D;
                border-radius: 3px;
                text-align: center;
                color: #C65B0E;
                font-size: 16px;
                font-family: 'nunitoSemiBold';
                padding: 12px;
                min-width: 240px;
                margin: auto;
                width: 240px;
            }
            .card-block {
                display: flex;
                margin-top: 18px;
                text-align: center;
                padding: 0 20px;
                ion-card  {
                    width: 25%;
                    ion-card-header {
                        color: #000;
                        ion-card-title {
                            font-size: 30px;
                            font-family: 'nunitoBold';
                        }
                        ion-card-subtitle {
                            font-family: 'nunitoSemiBold';
                            font-size: 13px;
                            color: #000;
                        }
                    }
                    ion-card-content {
                        .des {
                            font-size: 13px;
                            color: #586A7A;
                            font-family: 'nunitoLight';
                            min-height: 58px;
                        }
                        .qualify-block {
                            font-size: 11px;
                            color: #FF0000;
                            margin-top: 16px;
                            ion-icon {
                                font-size: 21px;
                            }
                        }
                    }
                }
            }
        }
    // }

}

ion-modal.business-opportunities::part(content) { 
// .business-opportunities {
//     .modal-wrapper {
        max-width: 960px;  
        width: 960px;
        // height: 650px;
        height: 92vh;
        border-radius: 8px;

}
ion-modal.business-opportunities{
        .ion-page {
            // padding: 30px 3px;
            padding: 0;
            ion-header {
                background: #F7F7F7;
                // height: 110px;
                height: 75px;
                .close {
                    right: 16px;
                    color: #8D8989;
                    top: 0px;
                    font-family: 'nunitoRegular';
                    font-size: 26px;
                    position: absolute;
                    cursor: pointer;
                }
                .title {
                    font-size: 22px;
                    color: #212121;
                    font-family: 'nunitoSemiBold';
                    text-align: left;
                    display: block;
                    padding: 25px 16px;
                    span {
                        font-family: 'nunitoExtraBold';
                    }
                }
            }
            ion-content {
                // top: -35px;
                z-index: 999;
                width: 99.8%;
                --background: transparent;
                .top-section {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    .common-content-section {
                        .card-type-segment {
                            margin-bottom: 0;
                            ion-segment-button {
                                --indicator-height: 0;
                                flex: 0 1 auto;
                                min-width: 211px;
                                .card {
                                    .title {
                                        font-size: 13.5px;
                                        color: #797EAC;
                                        font-family: 'nunitoSemiBold';
                                    }
                                    .value {
                                        font-size: 15.5px;
                                    }
                                }
                            }
                        }
                    }
                    .filter-section {
                        padding: 0;
                        border: none;
                        background: transparent;
                        margin-bottom: 16px;
                        ion-segment {
                            width: unset;
                            ion-segment-button {
                                border-radius: 16px;
                                min-height: 30px;
                            }
                        }
                    }
                }
                .search-section-changed-revamped {
                    margin: 8px 16px;
                }
                .web-equity {
                    margin: 0 16px;
                }
            }
        }
    // }

}

// .addRemark .modal-wrapper {
//     max-width: 500px !important;
//     width: 500px !important;
//     height: calc(100vh - 50px);
//     border-radius: 8px;
// }

ion-modal.addRemark::part(content) {
    max-width: 500px !important;
    width: 500px !important;
    height: calc(100vh - 50px);
    border-radius: 8px;
}

// css changed for modal-wrapper	
ion-modal.client-details::part(content) {
    max-width: 900px !important;
    width: 900px !important;
    height: calc(100vh - 100px);
    border-radius: 8px;
}
ion-modal.client-details {
    .ion-page {
        padding: 30px 5px 35px !important;
    }
}


// cancel request popup
// css changed for modal-wrapper
ion-modal.cancel-request::part(content) { 
    width: 380px;
    height: 157px;
}
ion-modal.cancel-request {        
    .ion-page {
        .title {
            font-size: 20px;
            margin-top: 10px;
        }
        .message {
            text-align: center;
            margin-top: 20px;
            color: #586A7A;
            font-size: 14px;
        }
    }
}

on-modal.jv-approveRejectRes-request::part(content) { 
    width: 600px;
    height: 400px;
}
ion-modal.cancel-request {        
    .ion-page {
        .title {
            font-size: 20px;
        }
        .message {
            text-align: center;
            margin-top: 20px;
            color: #586A7A;
            font-size: 14px;
        }
    }
}
.jv-request{
    .modal-wrapper {
        // height: 250px;
    }
    
}

// css changed for modal-wrapper	
ion-modal.ipo-modal::part(content) { 
    max-width: 410px;  
    // height: 250px;
    height: 285px;
    border-radius: 8px;
}
ion-modal.ipo-modal {
    ion-backdrop {
        opacity: .7 !important;
    }
    .ion-page {
        position: absolute;
        padding: 30px 30px 35px;
        .table-wrapper{
            padding-top: 20px;
        }
        /* .header-table {
            width: 100%;
            // background: #F2F4F4;
            margin-top: 20px;
            thead {
                background: #f2f4f2;
                th {
                    text-align: right;
                    padding: 12px;
                    color: #6E6E6E;
                    font-size: 12px;
                }
                th:first-child {
                    border-radius: 4px 0 0 4px;
                    text-align: left;
                    width: 47%;
                    padding-left: 16px;
                }
                th:nth-child(2) {
                    width: 20%;
                    padding: 12px 6px;
                }
                th:last-child {
                    border-radius: 0 4px 4px 0;
                    width: 33%;
                    padding-right: 16px;
                }
            }
        }
        .table-wrapper {
            height: 209px;
            overflow: auto;
        }
        .body-table {
            width: 100%;
            tr {
                border-bottom: 1px solid #CBCBCB;
                td {
                    text-align: right;
                    padding: 12px;
                    color: #000000;
                    font-family: nunitoSemiBold;
                    font-size: 14px;
                    width: 30%;
                }
                td:first-child {
                    width: 40%;
                    text-align: left;
                    max-width: 100px;
                    padding-left: 16px;
                }
                td:nth-child(2) {
                    // width: 20%;
                    padding: 12px 6px;
                    text-align: center;
                }
                td:last-child {
                    // width: 33%;
                    padding-right: 16px;
                    padding-left: 0;
                    max-width: 25vw;
                    white-space: nowrap;
                    text-overflow: ellipsis !important;
                    overflow: hidden;
                    text-align: right;
                }
            }
            tr:last-child {
                border: none;
            }
        } */
        h5:not(:last-child){
            border-bottom: 1px solid #eee;
            margin-top: 10px;
            padding-bottom: 10px;
        }
        h5{
            color: #2680EB;
            cursor: pointer;
        }
        .close {
            position: absolute;
            right: -16px;
            top: -30px;
            color: #C1C4CC;
            font-family: nunitoExtraBold;
            font-size: 23px;
            cursor: pointer;
        }
        .title {
            display: flex;
            justify-content: center;
            color: #000;
            font-family: nunitoExtraBold;
            font-size: 16px;
            span {
                border-bottom: 3px solid var(--ion-red-secondary-color);
                padding: 0 5px 2px;
            }
        }
    }
}

// css changed for modal-wrapper
ion-modal.no-data-modal::part(content) { 
    max-width: 285px;
    height: 84px;
    border-radius: 8px;
}
ion-modal.no-data-modal {        
    .ion-page {
        padding: 24px 30px 20px;
        .close {
            top: -23px;
        }
        .no-data-message {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;
            font-size: 18px;
        }
    }
}

// css changed for modal-wrapper
ion-modal.demat-modal::part(content) {
    max-width: 410px;  
    height: 450px;
    border-radius: 8px;
}

ion-modal.demat-modal {
    .ion-page {
        height: 440px !important;
    }
}

// updated css for modal
ion-modal.indices-details::part(content) {
    max-width: 615px;
    width: 615px;
    // max-height: 594px;
    height: calc(100vh - 108px);
    border-radius: 8px;
}

ion-modal.indices-details {        
    .ion-page {
        .title {
            justify-content: flex-start;
            span {
                padding: 0 18px 2px;
                border-bottom: none;
                font-size: 20px;
                font-family: 'nunitoBold';
                text-transform: capitalize;
            }
        }
        .graph-section {
            height: 220px;
            img {
                height: 100%;
                width: 100%;
            }
        }
        .header-table {
            thead {
                background: none;
                th {
                    background: #f2f4f2;
                    width: 14% !important;
                    text-align: center;
                }
                th:first-child {
                    border-radius: 8px 0 0 0;
                    width: 16% !important;
                    text-align: left;
                }
                th:last-child {
                    border-radius: 0 8px 0 0;
                    text-align: right;
                }
            }
        }
        .table-wrapper {
            border: 1px solid #CBCBCB;
            border-radius: 0 0 8px 8px;
            height: auto;
            max-height: calc(100vh - 500px);
            .body-table {
                tr {
                    td {
                        max-width: 180px !important;
                        white-space: unset !important;
                        width: 14% !important;
                        text-align: center;
                        .lower {
                            font-size: 12px;
                            font-family: 'nunitoRegular';
                        }
                    }
                    td:nth-child(1) {
                        width: 16% !important;
                        text-align: left;
                    }
                    td:last-child{
                        text-align: right;
                    }
                    td:nth-child(2) {
                        padding: 12px 6px;
                        //text-align: right;
                    }
                    .no-data-found {
                        // padding-top: 90px !important;
                    }
                }
            }
        }
    }
}

// css changed for modal-wrapper
ion-modal.exposure-list::part(content) { 
    max-width: 960px !important;
    width: 960px !important;
}
ion-modal.exposure-list{
    .ion-page {
        .body-table {
            td:nth-child(2) {
                text-align: center !important;
            }
        }
    }    
}

// css changed for modal-wrapper	
ion-modal.Pay-detail-model::part(content) { 
    max-width: 800px !important;
    width: 800px !important;
}
ion-modal.Pay-detail-model{
    .ion-page {
        padding: 30px 0px 35px !important;
        .close{
            right: 15px !important;
        }
    }
}


// support popup
ion-modal.support {
    .ion-page {
        .table-wrapper {
            height: 316px;
            overflow: auto;
        }
        .body-table {
            margin-top: 12px;
            tr {
                td {
                    padding: 12px 0 !important;
                }
            }
        }
        .title {
            span {
                padding: 0 0 2px;
            }
        }
        .block {
            display: flex;
            flex-direction: column;
            .type {
                font-size: 14px;
                font-family: 'nunitoSemiBold';
            }
            .detail {
                font-size: 12px;
                color: #9C9C9C;
            }
        }
        .mobile-listing {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            color: #2680EB;
            font-family: 'nunitoBold';
        }
    }
}

// .support {
//     .modal-wrapper {
//         .ion-page {
//             .table-wrapper {
//                 height: 316px;
//                 overflow: auto;
//             }
//             .body-table {
//                 margin-top: 12px;
//                 tr {
//                     td {
//                         padding: 12px 0 !important;
//                     }
//                 }
//             }
//             .title {
//                 span {
//                     padding: 0 0 2px;
//                 }
//             }
//             .block {
//                 display: flex;
//                 flex-direction: column;
//                 .type {
//                     font-size: 14px;
//                     font-family: 'nunitoSemiBold';
//                 }
//                 .detail {
//                     font-size: 12px;
//                     color: #9C9C9C;
//                 }
//             }
//             .mobile-listing {
//                 display: flex;
//                 flex-direction: column;
//                 align-items: flex-end;
//                 color: #2680EB;
//                 font-family: 'nunitoBold';
//             }
//         }
//     }
// }

// add user popup for invest page
// css changed for modal-wrapper
ion-modal.add-user::part(content) { 
    max-width: 473px;  
    height: 443px;
    border-radius: 8px;
}
ion-modal.add-user {
    ion-backdrop {
        opacity: .7 !important;
    }
    .ion-page {
        padding: 16px 38px;
        .add-user-segment {
            flex-direction: column;
            display: flex;
            // height: 341px;
            justify-content: space-between;
            margin-top: 30px;
            gap: 20px;
            ion-segment-button {
                border: 1px solid #9B9B9B;
                height: 64px !important;
                --height: 64px !important;
                flex: unset;
                width: 100%;
                max-width: unset;
                border-radius: 3px;
                --border-radius: 3px;
                --ripple-color: transparent;
                .segment-inner-data {
                    display: flex;
                    width: 100%;
                    .icon {
                        width: 34px;
                    }
                    .user-type-block {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        padding-left: 8px;
                        .user {
                            font-size: 15px;
                            font-family: 'nunitoSemiBold';
                            letter-spacing: 0;
                            text-transform: capitalize;
                            color: #000;
                        }
                        .user-type {
                            font-size: 11px;
                            color: #6E6E6E;
                            margin-top: 3px;
                            font-family: 'nunitoRegular';
                            text-transform: none;
                            letter-spacing: 0;
                        }
                    }
                }
            }
            .segment-button-checked {
                border: 1px solid #F37921;
                --indicator-height: 0;
                background: #F379211A;
            }
        }
        .user-block {
            display: flex;
            justify-content: space-between;
            padding-top: 36px;
            .user-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                span {
                    font-size: 14px;
                    font-family: nunitoSemiBold;
                    color: #000;
                }
                .icon {
                    img {
                        height: 30px;
                    }
                }
            }
        }
        .close {
            position: absolute;
            right: -24px;
            top: -10px;
            color: #C1C4CC;
            font-family: nunitoExtraBold;
            font-size: 23px;
            cursor: pointer;
        }
        .title {
            display: flex;
            justify-content: center;
            color: #000;
            font-family: nunitoExtraBold;
            font-size: 16px;
            span {
                border-bottom: 3px solid var(--ion-red-secondary-color);
                padding: 0 5px 2px;
            }
        }
    }
}

// coming soon popover
.coming-soon-popover {
    ion-item {
        text-align: center;
    }
}

.select-popover {
    .popover-content {
        margin-top: 10px;
        width: 386px;
        ion-item {
            --min-height: 32px;
            --ripple-color: transparent;
        }
        ion-label {
            font-size: 14px !important;
            margin: 0 !important;
            color: #212121 !important;
            font-family: 'nunitoSemiBold';
        }
    }
}

.ledger-popover{
    --background: var(--ion-color-light);
    &::part(content) {
       
    }
    .popover-content {
       width: 500px !important;
       transform: translate(-14%, 8%) !important;
        right:50px !important;
        top:25px;

        ion-list{
            background-color: #EDEDED;
        }
    }
}

.rupee {
    width: 10px;
}

// filter popup
ion-modal.filter::part(content) {
    --background: transparent;
    max-width: 340px;
}
ion-modal.filter { 
    .ion-page {
        top: 70% !important;
        // height: 400px;
        height: 328px;
        background: #fff;
        border-radius: 10px 10px 0 0;
        padding: 16px 16px 0 16px;
        .close {
            position: absolute;
            right: 16px;
            top: 5px;
            color: #C1C4CC;
            font-family: nunitoExtraBold;
            font-size: 23px;
            cursor: pointer;
        }
        .title {
            display: flex;
            justify-content: center;
            color: #000;
            font-family: nunitoExtraBold;
            font-size: 14px;
            span {
                border-bottom: 3px solid var(--ion-red-secondary-color);
                padding-bottom: 2px;
                padding: 0 8px;
                // font-size: 16px;
            }
            
        }
        .items {
            display: flex;
            border-bottom: 1px solid #cbcbcb;
            padding: 20px 16px;
            margin-top: 10px;
            .prefix {
                color: #9C9C9C;
                margin-right: 5px;
                font-family: nunitoLight;
            }
            .filter-type {
                font-family: 'nunitoSemiBold';  color:#000;
            }
        }
        .active {
            color: #F37921;
            .prefix {
                color: #F37921;
            }
        }
    }
}

// .filter {
//     .modal-wrapper {
//         --background: transparent;
//         max-width: 340px;
//         .ion-page {
//             top: unset !important;
//             // height: 400px;
//             height: 328px;
//             background: #fff;
//             border-radius: 10px 10px 0 0;
//             padding: 16px 16px 0 16px;
//             .close {
//                 position: absolute;
//                 right: 16px;
//                 top: 5px;
//                 color: #C1C4CC;
//                 font-family: nunitoExtraBold;
//                 font-size: 23px;
//                 cursor: pointer;
//             }
//             .title {
//                 display: flex;
//                 justify-content: center;
//                 color: #000;
//                 font-family: nunitoExtraBold;
//                 font-size: 14px;
//                 span {
//                     border-bottom: 3px solid var(--ion-red-secondary-color);
//                     padding-bottom: 2px;
//                     padding: 0 8px;
//                     // font-size: 16px;
//                 }
                
//             }
//             .items {
//                 display: flex;
//                 border-bottom: 1px solid #cbcbcb;
//                 padding: 20px 16px;
//                 margin-top: 10px;
//                 .prefix {
//                     color: #9C9C9C;
//                     margin-right: 5px;
//                     font-family: nunitoLight;
//                 }
//                 .filter-type {
//                     font-family: 'nunitoSemiBold';  color:#000;
//                 }
//             }
//             .active {
//                 color: #F37921;
//                 .prefix {
//                     color: #F37921;
//                 }
//             }
//         }
//     }
// }

// .tradebook {
//     .modal-wrapper {
//         .ion-page {
//             height: 255px;
//         }
//     }
// }

ion-modal.tradebook { 
    .ion-page {
        height: 255px;
    }
}

// contract info popup
// css changed for modal-wrapper	
ion-modal.contract-info::part(content) { 
    height: 500px;
}

ion-modal.contract-info {
    .ion-page {
        padding: 16px;
        padding-right: 3px;
        .close {
            right: 10px;
            top: -13px;
        }
        .table-wrapper {
            height: 440px;
            overflow: auto;
            padding: 16px;
        }
        .body-table {
            tr {
                td {
                    padding: 24px 0;
                }
                td:first-child {
                    width: 38%;
                    max-width: 132px;
                    padding-left: 0px;
                    font-family: nunitoSemiBold
                }
                td:last-child {
                    width: 62%;
                    padding-right: 0;
                    font-family: nunitoBold
                }
            }
        }
    }
}

// add to watchlist popup
// css changed for modal-wrapper	
ion-modal.add-to-watchlist::part(content) { 
    max-width: 354px;  
    height: 418px;
    border-radius: 8px;
}
ion-modal.add-to-watchlist {
    ion-backdrop {
        opacity: .7 !important;
    }
    .ion-page {
        padding: 30px 30px 24px;
        .table-wrapper {
            padding-right: 6px;
            padding: 0px;
            // max-height: 187px;
            // overflow: auto;
        }
        .header-table {
            width: 100%;
            // background: #F2F4F4;
            // margin-top: 20px;
            margin: 20px auto 0;
            thead {
                background: #eeeeee;
                th {
                    text-align: right;
                    padding: 12px;
                    color: #6E6E6E;
                    font-size: 12px;
                }
                th:first-child {
                    border-radius: 4px 0 0 4px;
                    text-align: left;
                    width: 50%;
                    padding-left: 16px;
                }
                // th:nth-child(2) {
                //     width: 20%;
                // }
                th:last-child {
                    border-radius: 0 4px 4px 0;
                    width: 50%;
                    padding-right: 16px;
                }
                .parent-header {
                    .upper-part {
                        color: #F55858;
                        font-size: 14px;
                        .name {
                            color: #000;
                            font-family: nunitoBold;
                            font-size: 14px;
                        }
                        .option {
                            font-size: 12px;
                            color: #9C9C9C;
                        }
                    }
                    .lower-part {
                        font-size: 12px;
                        .light-font {
                            font-family: nunitoLight;
                            color: #F55858;
                        }
                        span {
                            color: #9C9C9C;
                        }
                        
                    }
                }
            }
        }
        // .table-wrapper {
        //     height: 209px;
        //     overflow: auto;
        // }
        .body-table {
            width: 92%;
            margin: auto;
            tr {
                border-bottom: 1px solid #CBCBCB;
                td {
                    text-align: right;
                    padding: 18px 12px;
                    color: #000000;
                    font-family: nunitoSemiBold;
                    font-size: 14px;
                }
                .checkbox-block {
                    display: flex;
                    align-items: center;
                    span {
                        margin-left: 6px;
                    }
                }
                td:first-child {
                    width: 85%;
                    text-align: left;
                    max-width: 100px;
                    padding-left: 0px;
                }
                // td:nth-child(2) {
                //     width: 20%;
                // }
                td:last-child {
                    width: 15%;
                    padding-right: 8px;
                    font-family: nunitoBold;
                }
            }
            tr:last-child {
                border: none;
            }
        }
        .add-btn {
            display: flex;
            justify-content: center;
            margin-top: 18px;
            // .submit-button {
            //     width: 216px;
            // }
        }
        // .view-more {
        //     font-size: 14px;
        //     text-align: center;
        //     color: #2680EB;
        //     margin-top: 30px;
        //     font-family: nunitoSemiBold;
        // }
        .close {
            position: absolute;
            right: -16px;
            top: -30px;
            color: #C1C4CC;
            font-family: nunitoExtraBold;
            font-size: 23px;
            cursor: pointer;
        }
        .title {
            display: flex;
            justify-content: center;
            color: #000;
            font-family: nunitoExtraBold;
            font-size: 16px;
            span {
                border-bottom: 3px solid var(--ion-red-secondary-color);
                padding: 0 5px 2px;
            }
        }
    }
}

.d-flex {
    display: flex !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.flex-direction-column {
    flex-direction: column !important;
}

.justify-center {
    justify-content: center !important;
}

.justify-between {
    justify-content: space-between !important;
}

.custom-checkbox {
    --background-checked: transparent;
    --border-color-checked: #17C257;
    --checkmark-color: #17C257;
    --size: 16px;
    --border-width: 1px;
}

// forgot password popup for desktop
// css changed for modal-wrapper	
ion-modal.forgot-password-popup-desktop::part(content) { 
    // height: 50vh;
    margin: 0 16px;
    max-width: 340px;
    height: 420px;
    // border-radius: 10px;
    --background: transparent;
}
ion-modal.forgot-password-popup-desktop{
    ion-backdrop{
        opacity: .8 !important;
    }
    .ion-page{
        // height: 315px;
        // top: unset;
        // border-radius: 10px;
        // padding: 30px;
        // background: #fff;
        ion-list{
            margin-top: 30px;
        }
        .close {
            position: absolute;
            right: 16px;
            top: 5px;
            color: #C1C4CC;
            font-family: nunitoExtraBold;
            font-size: 23px;
            cursor: pointer;
        }
        .title {
            display: flex;
            justify-content: center;
            color: #000;
            font-family: nunitoExtraBold;
            font-size: 14px;
            span {
                border-bottom: 3px solid var(--ion-red-secondary-color);
                padding-bottom: 2px;
                // font-size: 16px;
            }
        }
        .active{
            --background:#EEEEEE !important;
            color: #000 !important;
            font-family: nunitoBold;
            border-bottom: none;
        }
        .digit{
            font-family: nunitoBold;
            color: #000;
        }
        ion-button{
            width: 100%;
            color: #212121;
            font-size: 14px;
            font-family: nunitoSemiBold;
            --background: transparent;
            --box-shadow: none;
            --background-activated: transparent;
            --background-hover: transparent;
            --background-focused: transparent;
            --border-radius: 6px;
            height: 54px;
            border-bottom: 1px solid #EEEEEE;
            .item-block{
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
        }
        
    }
}

// forgot password popup for mobile
// changed css. review
ion-modal.forgot-password-popup-mobile::part(content) { 
    // height: 50vh;
    margin: 0 16px;
    max-width: 340px;
    // height: 370px;
    // border-radius: 10px;
    --background: transparent;
}

ion-modal.forgot-password-popup-mobile{
    ion-backdrop{
        opacity: .6 !important;
    }
    .ion-page{
        // height: 315px;
        top: unset;
        height: 500px;
        // background: #fff;
        // border-radius: 10px 10px 0 0;
        // padding: 16px 16px 30px;
        ion-list{
            margin-top: 30px;
        }
        .close {
            position: absolute;
            right: 16px;
            top: 5px;
            color: #C1C4CC;
            font-family: nunitoExtraBold;
            font-size: 23px;
            cursor: pointer;
        }
        .title {
            display: flex;
            justify-content: center;
            color: #000;
            font-family: nunitoExtraBold;
            font-size: 14px;
            span {
                border-bottom: 3px solid var(--ion-red-secondary-color);
                padding-bottom: 2px;
                // font-size: 16px;
            }
        }
        .active{
            --background:#EEEEEE !important;
            color: #000 !important;
            font-family: nunitoBold;
            border-bottom: none;
        }
        .digit{
            font-family: nunitoBold;
            color: #000;
        }
        ion-button{
            width: 100%;
            color: #212121;
            font-size: 14px;
            font-family: nunitoSemiBold;
            --background: transparent;
            --box-shadow: none;
            --background-activated: transparent;
            --background-hover: transparent;
            --background-focused: transparent;
            --border-radius: 6px;
            height: 54px;
            border-bottom: 1px solid #EEEEEE;
            .item-block{
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
        }
        
    }
}

// session expired pop up
ion-modal.session-expired::part(content) {
    height: 400px;
}
ion-modal.session-expired {
    .ion-page {
        height: 400px;
    }
}

// successfull popup

ion-modal.successfull-popup::part(content) { 
    // height: 50vh;
    margin: 0 16px;
    max-width: 340px;
    height: 220px;
    // border-radius: 10px;
    --background: transparent;
}

ion-modal.successfull-popup {
    ion-backdrop{
        opacity: .8 !important;
    }
    .ion-page{
        // height: 315px;
        // top: unset;
        background: #fff;
        border-radius: 10px;
        padding: 53px;
        background: #fff;
        ion-list{
            margin-top: 30px;
        }
        .container-block {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .icon {
            ion-icon {
                font-size: 45px;
                color: #17C257;
            }
        }
        .close {
            position: absolute;
            right: 16px;
            top: 5px;
            color: #C1C4CC;
            font-family: nunitoExtraBold;
            font-size: 23px;
            cursor: pointer;
        }
        .heading {
            font-size: 14px;
            font-family: nunitoSemiBold;
            color: #212121;
        }
        .message {
            font-size: 12px;
            color: #A7A7A7;
            font-family: nunitoLight;
            text-align: center;
        }
    }
}

// .successfull-popup{
//     ion-backdrop{
//         opacity: .8 !important;
//     }
//     .modal-wrapper{
//         // height: 50vh;
//         margin: 0 16px;
//         max-width: 340px;
//         height: 220px;
//         // border-radius: 10px;
//         --background: transparent;
//         .ion-page{
//             // height: 315px;
//             // top: unset;
//             background: #fff;
//             border-radius: 10px;
//             padding: 53px;
//             background: #fff;
//             ion-list{
//                 margin-top: 30px;
//             }
//             .container-block {
//                 display: flex;
//                 flex-direction: column;
//                 align-items: center;
//             }
//             .icon {
//                 ion-icon {
//                     font-size: 45px;
//                     color: #17C257;
//                 }
//             }
//             .close {
//                 position: absolute;
//                 right: 16px;
//                 top: 5px;
//                 color: #C1C4CC;
//                 font-family: nunitoExtraBold;
//                 font-size: 23px;
//                 cursor: pointer;
//             }
//             .heading {
//                 font-size: 14px;
//                 font-family: nunitoSemiBold;
//                 color: #212121;
//             }
//             .message {
//                 font-size: 12px;
//                 color: #A7A7A7;
//                 font-family: nunitoLight;
//                 text-align: center;
//             }
//         }
//     }
// }

.destop-segment-middle{
    --background: transparent;
    height: 42px;
    margin: 0;
    // margin: 0 16px;
    min-height: 42px;
    ion-segment-button{
        // text-transform: capitalize;
        font-family: nunitoLight;
        height: 42px;
        min-height: 42px;
        font-size: 16px;
        --color-checked: #2D197F;
        text-transform: capitalize;
    }
    .segment-button-checked {
        --indicator-height: 4px; 
        // font-family: nunitoSemiBold;
        font-family: 'nunitoBold';
        --color: #2D197F;
        // font-weight: bold;
            ion-button{
                --color: #2D197F;
                font-weight: bold;
            }  
        }
    ion-button{
        --color: #586A7A;
    }

    
}


.desktop-segment-bottom{
    // margin: 0 -8px;
    // width: 1115px;
    --background: transparent;
    width: calc(100% - 55px) !important;
    ion-segment-button{
        // --background-focused: red;
        font-family: nunitoLight;
        --background-checked: #F37921;
        --border-radius: 30px;
        // color: #fff;
        font-size: 16px;
        // height: 30px;
        --indicator-height: 0;
        min-height: 34px;
        --color-checked: #fff;
        --background: transparent;
        margin: 0 16px 0 0;
        // --border-width: 2px;
        --border-color: #aca6d8;
        // text-transform: capitalize;
        text-transform: none;
        --color: #586A7A;
        --color-checked: #fff;
        .segment-block {
            .desktop-badge {
                margin-left: 3px;
                // background: #F37921;
                color: #ffffff;
                background: #586A7A;
            }
        }
    }
    .segment-button-checked {
        --border-width: 0 !important;
        min-height: 34px !important;
        font-weight: normal;
        font-family: nunitoSemiBold;
        background: #F37921;
        // color: #fff;
        .segment-block {
            .desktop-badge {
                margin-left: 3px;
                background: #fff;
                color: #F37921;
            }
        }
    }

    
}

.border-bottom{
    border-bottom: 1px solid silver;
}

.first-segment-desktop{
    margin-left: 0 !important;
}

.desktop-table{
    // margin: 0 11vw;
    // max-height: 400px;
    margin: auto;
    width: 1140px;
    // max-height: 370px;
    // border-bottom: 1px solid #f1f1f1;
    // max-height: 54vh;
    //max-height: calc(100vh - 356px);
    overflow-y: visible;
    table{
        border: 1px solid silver;
    }
    
}



.toolbar-paddding-0{
    --padding-start: 0 !important;
    --padding-end: 0 !important;
    --padding-top: 0 !important;
    --padding-bottom: 0 !important;
}

// thin scroll css

#thin-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

#thin-scroll::-webkit-scrollbar {
    width: 4px;
    background-color: #F5F5F5;
}

#thin-scroll::-webkit-scrollbar-thumb {
    background-color: #2D197F;
}

// thin scroll css with class

.thin-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.thin-scroll::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #F5F5F5;
}

.thin-scroll::-webkit-scrollbar-thumb {
    background-color: #2D197F;
}
.header-md::after{
    content: none;
}
.datePopover{
    position: relative;
    min-width: 160px;
}
.calendarOpen{
    position: absolute;
    --ion-color-base: #f37921 !important;
    border-radius: 8px;
    // transform: translate(-35%, 0);
    // left: -35%;
    z-index: 2060;
    width: 320px;
    min-height: 320px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    @media (min-width:992px) {
        right: 0;
        transform: translate(-50px, 0px);
    }
}
.calendarBackdrop{
    position: fixed;
    background:transparent;
    z-index: 1060;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.total_start_date{
    .calendarOpen{
        transform: translate(0, 0);
        left: 0;
    }
}
@media (max-width:768px) {
    .calendarOpen{
        left: 0 !important;
        z-index: 2060 !important;
        width: 320px !important;
        min-height: 320px !important;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
        right: 0 !important;
        margin: 0 auto !important;
        transform: translate(0, -50%) !important;
        position: fixed;
        top: 50%;
    }
    .calendarBackdrop{
        background: rgba(0, 0, 0, 0.3);
        z-index: 2000;
    }
}

.date_Picker {
 --background:transparent;
 --border-width:0px;
 --min-height:30px;
 margin-left: -16px;
 display: flex;
    width: 100%;
    --highlight-color-focused: transparent;
    --highlight-color-valid: transparent;
    --highlight-color-invalid: transparent;
    >div{
    padding-left: 0;
 }
 .ion-lable-end{
    font-size: 12px;
    white-space: nowrap;
    margin: 5px 0;
    --padding-start:0;
    width: 57px;
}
 .ion-text-end input{
    height: 18px;
font-size: 12px!important;
color: #000;
padding: 0 0 0 2px;
border-width: 0 0 1px;
border-color: #cbcbcb;
background-color:transparent;
min-width: 75px;
margin-left: 5px;
width: 75px;
max-width: 75px;
}

}
.form_date_picker{
    height: 36px;
    font-size: 15px;
    --padding-top: 5px;
    display: block;
    ion-input{
        min-height: 30px!important;
    }
    .item-native{
        padding: 0 10px!important;
        height: 30px;
        width: 100%;
        .item-inner{
            border-width: 0!important;
        }
    }
    
}
.date_Picker.form_date_picker .ion-text-end input {
    font-size: 14px !important;
    margin-left: 0;
    padding:0;
}
.desktop-header-table{
    // padding: 0 11vw 0 10vw;
    height: 48px;
    --padding-top: 0;
    --padding-bottom: 0;
    // width: 1170px;
    margin: auto;
}

.heading-part {
    display: flex;
    align-items: center;
    margin: 24px 0 6px;
    @media screen and (min-width:992px) {
        margin-top: 10px;
        height:36px;
    }
    .back-button {
        font-size: 24px;
        cursor: pointer;
    }
    .heading {
        margin-left: 8px;
        font-family: 'nunitoBold';
        font-size: 22px;
        color: #212121;
    }
}

ion-toolbar.desktop-header-table {
    height: auto;
    --min-height: 40px;
}

.desktop-header-table th {
    // width: 14.28%;
}

.desktop-table td {
    // width: 14.28%;
    padding: 0 !important;
}

.edit-block{
    width: 50px;
    position: relative;
    top: 18px;
    text-align: right;
    color: #f37921;
    font-size: 12px;
    padding-top: 5px;
    cursor: pointer;
    span{
        margin-left: 5px;
        font-size: 16px;
        font-family: nunitoSemiBold;
    }
}

.rotate {
    transform: rotate(180deg);
    // padding-top: 20px;
}

.arrow-button{
    --box-shadow: none;
    --background: transparent;
    margin: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    // height: auto;
    color: #757382;
    position: relative;
    // top: -05px;
    right: -14px;
    --padding-end: 8px;
    // --backgournd-activated: transparent;
    // --backgournd-focused: transparent
    ion-icon{
        color: #757382;
        font-size: 14px;
    }
}

.down-arrow-button{
    --box-shadow: none;
    --background: transparent;
    position: relative;
    // top: 8px;
}

.tab-selected{
    --background: #F6F6F5;
    border-top: 2px solid #593cc7;
    color: #593cc7;
}

ion-fab-button{
    --background: #593cc7 !important;
    --background-activated: #593cc7 !important;
}

ion-tab-button{
    border-top: 3px solid transparent;
    ion-icon{
        font-size: 19px;
        margin-bottom: 6px;
    }
}

.td-block{
    padding: 8px;
    height: 40px;
    padding-top:10px;
    cursor: pointer;
}

th {
    .td-block{
       height: auto;
       min-height: 40px;
    }
}

.inner-tr-style{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inner-tr-style {
    span:nth-child(2) {
        font-size: 16px;
        font-weight: bold;
        padding-top: 3px;
        color: #212121;
        font-family: nunitoSemiBold;
        max-width: 150px;
    }
}

// web equity and commodity
.web-equity {
    .header-part {
        display: flex;
        justify-content: space-between;
        width: 100%;
        background: #F2F4F4;
        // padding: 18px;
        padding: 6px 18px;   // this padding is added to increase visibility of components
        // margin-bottom: 16px;
        margin-bottom: 10px;
        border-radius: 8px;
        align-items: center;
        .title {
            font-size: 18px;
            color: #6E6E6E;
        }
        .value {
            font-size: 26px;
            font-family: 'nunitoSemiBold';
            // color: #000;
        }
        .rupees {
            font-size: 18px !important;
        }
        .percent-value {
            font-size: 26px;
            font-family: 'nunitoLight';
            margin-left: 8px;
        }
        ion-searchbar {
            text-align: center !important;
            border-bottom: 1px solid silver;
            --box-shadow: none !important;
            padding-inline-start: 0;
            font-family: nunitoSemiBold;
            color: #000000;
            padding: 4px 0;
            --background: transparent !important;
        }
    }
    .header-table {
        th {
            padding: 10px 2px;
            background: #F2F4F4;
            width: 15%;
            color: #6E6E6E;
            text-align: right;
            max-width: 156px;
        }
        th:last-child {
            padding-right: 20px;
        }
    }
    // .main-table {
    //     td {
    //         padding: 10px 2px;
    //         width: 15%;
    //         color: #212121;
    //         text-align: right;
    //         max-width: 156px;
    //     }
    // }
    th:first-child, td:first-child {
        text-align: left !important;
        padding-left: 20px !important;
        width: 25% !important;
        max-width: 260px;
    }
    .main-table {
        border: 1px solid #cbcbcb;
        border-radius: 0 0 10px 10px;
        border-top: none;
        margin-bottom: 24px;
        tr {
            border-bottom: 1px solid #cbcbcb;
        }
        tr:last-child {
            border-bottom: none;
        }
        td {
            padding: 10px 2px;
            width: 15%;
            color: #212121;
            text-align: right;
            max-width: 156px;
            .td-heading {
                height: auto;
            }
        }
        td:last-child {
            padding-right: 20px;
        }
    }
}


.table-after {
    width: 2%;
    height: 40px;
    background: #f2f4f4;
    position: absolute;
    right: 0;
    top: 0;
}

.desktop-header-table {
    th:first-child{
        border-radius: 10px 0 0 0 !important;
    }
    th:last-child{
        border-radius: 0 10px 0 0 !important;
    }
}

.client-header-table th.noRMFAN:first-child{
    border-radius: 10px 10px 0 0 !important;
}

.common-header-table {
    thead {
        background-color: #F2F4F4;
        th:first-child{
            border-radius: 10px 0 0 0 !important;
        }
        th:last-child{
            border-radius: 0 10px 0 0 !important;
        }
        th {
            padding: 8px !important;
            font-size: 14px !important;
            color: #6E6E6E !important;
            text-align: left;
        }
    }
}

.common-body-table {
    // border: 1px solid #f2f2f2;
    // border-bottom: none;
    overflow: auto;
    overflow-x: hidden;
    max-height: 185px;
    td {
        text-align: left;
        padding: 8px !important;
    }
}

.common-footer-table {
    td {
        padding: 8px !important;
    }
}

.p0_m0{
    padding:0 !important;
    margin:0 !important;
}

.p-0 {
    padding: 0 !important;
}

.p-t-4{
    padding-top: 4px !important;
}

.p-t-6{
    padding-top: 6px !important;
}

.p-t-16{
    padding-top: 16px !important;
}

.p-b-2{
    padding-bottom: 2px !important;
}

.p-b-4{
    padding-bottom: 4px !important;
}

.p-b-16{
    padding-bottom: 16px !important;
}

.p-end-6 {
    --padding-end:6px;
}

.p-end-32 {
    --padding-end:32px;
}



.field-block {
    width: 100%;
    ion-item {
        --background-focused-opacity: 0;
    }
}

.circle-segment {
    .segment-button-checked {
        font-size: 15px;
        letter-spacing: 0;
        font-family: nunitoSemiBold;
    }
    ion-segment-button {
        max-width: 34px;
        --padding-start: 0;
        --background-checked: #64798C;
        --padding-end: 0;
        --color-checked: #fff;
        --border-radius: 50%;
        --color: #64798C;
        width: 34px;
        height: 34px;
        font-size: 14px;
        min-width: 34px;
        min-height: 34px;
        --indicator-height: 0;
    }
}


// toogle button for NSE toogle
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    // height: 22px;
    height: 21px;
  }
  
  .switch input {display:none;}
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
    border: 1px solid #F37921;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    // bottom: 2px;
    bottom: 1.5px;;
    background-color: #F37921;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    // background-color: #2ab934;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(33px);
    -ms-transform: translateX(33px);
    transform: translateX(33px);
  }
  
  /*------ ADDED CSS ---------*/
  .on
  {
    display: none;
  }
  
  .on
  {
    color: #6E768B;  
    // color: #17C257;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 30%;
    font-size: 12px;
    font-family: nunitoExtraBold;
    // right: -3%;
  }

  .off
  {
    color: #6E768B;
    // color:#F37921;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    // left: 50%;
    font-size: 12px;
    font-family: nunitoExtraBold;
    right: -3%;
  }
  
  input:checked+ .slider .on { 
    display: block;
  }
  
  input:checked + .slider .off {
      display: none;
   }

//   input:checked + .slider {
//     border-color: #17c257;;
//   }

//   input:checked + .slider:before {
//     background-color: #17c257;;
//   }
  
  /*--------- END --------*/
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
}

// buy cell toggle style
.buy-sell-switch {

    .slider {
        border: 1px solid #F55858 !important;
    }

    .slider:before {
        background-color: #F55858 !important;
    }

    input:checked + .slider {
        border-color: #17c257 !important;
    }

    input:checked + .slider:before {
        background-color: #17c257 !important;
    }
    .on
    {
        color: #17C257 !important;
    }

    .off
    {
        color:#F55858 !important;
    }
  
}

.last-duration-segment {
    ion-segment-button {
        font-family: nunitoLight;
        --color: #586A7A;
        --color-checked: #fff;
        --background-checked: #2D197F;
        text-transform: capitalize;
        font-size: 14px;
        letter-spacing: 0.5px;
        --indicator-height: 0;
        height: 26px;
        min-height: 26px;
        border-radius: 20px;
    }
}

.custom-calender {
    color: #F37921;
    font-size: 11px;
    .calender-img {
        width: 9px;
        margin-right: 6px;
    }
}

.jw-modal-close {
    font-family: nunitoExtraBold;
    color: #C1C4CC;
    font-size: 21px;
    cursor: pointer;
}

// for mobile css
.title-toggle-block {
    display: flex;
    justify-content: space-between;
    padding-right: 16px;
    margin-top: 16px;
}


.snapshot-mobile-header-first-table {
    tr {
        border: none !important;
    }
    th:first-child {
        border-radius: 10px 0 0 0 !important;
        border: none !important;
    }
    th {
        border: none !important;
    }
}

.snapshot-mobile-header-second-table {
    tr {
        border: none !important;
    }
    th:last-child {
        border-radius: 0 10px 0 0 !important;
        border: none !important;
    }
    th {
        border: none !important;
    }
}

.snapshot-mobile-header-single-table {
    tr {
        border: none !important;
    }
    th:first-child {
        border-radius: 10px 0 0 0 !important;
        border: none !important;
    }
    th:last-child {
        border-radius: 0 10px 0 0 !important;
        border: none !important;
    }
    th {
        border: none !important;
        font-size: 12px !important;
    }
}


   


// media query for desktop and mobile

@media screen and (max-width: 800px) {
    // .business-opportunities {
    //     .modal-wrapper {
    //         max-width: 760px;
    //         width: 760px;
    //     }
    // }
    ion-modal.business-opportunities::part(content) {
        max-width: 760px;
        width: 760px;
    }
}

@media screen and (max-width: 767px) and (min-width: 320px) {
    .ion-page {
        background: #fff;
    }
    .white-screen {
        background: #fff;
    }
    .container{
        width: 100%;
        margin: 0 auto;
        position: relative;
    }
    .desktop-visibility{
        display: none !important;
    }
    .mobile-visibility{
        display: block;
    }
    .mobile-visibility-flex{
        display: flex;
    }
    .mobile-tab-bar-visibility{
        display: flex;
    }
    .edit-block {
        top: 10px;
    }
    .login-container {
        .mobile-content-section {
            display: none !important;
        }
    }
}



.sorting-img {
    vertical-align: middle;
    margin-left: 5px;
    width: 7px;
}

.updated-sorting-img {
    vertical-align: middle;
    margin-left: 5px !important;
    width: 7px;
    display: inline;
}

.active:not(.sortingTable) {
    .updated-sorting-img {
        display: none;
    }
}

.sorting-img-ascending {
    vertical-align: middle;
    margin-left: 5px;
    width: 7px;
    display: none;
    transform: rotate(180deg);
}

.sorting-img-descending {
    vertical-align: middle;
    margin-left: 5px;
    width: 7px;
    display: none;
}

.active:not(.sortingTable) {
    .sorting-img-ascending {
        display: inline;
    }
    .sorting-img-descending {
        display: inline;
    }
}

.title-block-custom {
    display: flex;
    align-items: center;
    padding: 0 16px;
    justify-content: space-between;
    background: url('assets/svg/mobile-header-bg-smaller.svg');
    background-size: cover;
    height: 56px;
    .icon-title {
        display: flex;
        align-items: center;
        ion-icon {
            font-size: 24px;
            margin-bottom: 1px;
            position: relative;
            left: -2px;
            color: #fff;
        }
        .title {
            color: #fff;
            font-size: 20px;
            // font-family: nunitoBold;
            margin-right: 16px;
            padding-left: 8px;
            color: #ffffff;
            position: relative;
            padding-inline-end: 6px;
            font-family: nunitoBold;
            span {
                text-align: left;
                display: block;
                display: block;
                max-width: calc(100vw - 111px);
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}

.font-bold {
    font-family: 'nunitoBold' !important;
}

// calculator style 
.calculator {
        
    .container {
        width: 878px;
    }
    .reset-section {
        display: flex;
        justify-content: flex-end;
        margin-right: 8px;
        margin-top: 8px;
        .reset-block {
            cursor: pointer;
            display: flex;
            align-items: center;
            .reset-img {
                width: 14px;
                cursor: pointer;
            }
            .reset {
                color: #F37921;
                margin-left: 10px;
                font-size: 16px;
                cursor: pointer;
            }
        }
    }
    .form-block-section {
        display: flex;
        justify-content: space-between;
        .right-section {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-top: 16px;
            justify-content: center;
            .image-section {
                width: 100%;
                margin-top: 60px;
                img {
                    max-width: 340px;
                    width: 100%;
                }
            }
        }
    }

    .form-block {
        width: 50%;
    }

    .title {
        margin: 28px 0 0px;
        font-size: 22px;
        // font-family: nunitoBold;
        color: #2D197F;
    }

    .header-title {
        padding-left: 0;
    }

    .field-block {
        width: 100%;
        // padding: 0 35px;
        margin-top: 8px;
        ion-item {
            --padding-start: 0;
            --inner-padding-end: 0;
            --background-focused-opacity: 0;
        }
        ion-item:first-child {
            .field {
                margin-top: 16px !important;
            }
        }
        .field {
            width: 100%;
            background: #fff;
            ion-input {
                border: 1px solid #00083959;
                border-radius: 5px;
                font-family: nunitoBold;
                color: #000;
                --padding-end: 8px;
                font-size: 18px;
            }
            .eye-icon{
                color: #4F677A;
                position: absolute;
                right: 8px;
                width: 20px;
                height: 20px;
                z-index: 999;
                bottom: 8px;
            }
            ion-label {
                font-size: 14px;
                --color: #808080;
                margin-bottom: 3px;
                max-width: 100%;
            }
            .focus-label {
                --color: #F37921;
            }
            .focus-input{
                border: 2px solid #F37921;
            }
        }
    }
        
    .login-btn{
        margin-top: 44px;
        max-width: 280px;
        margin: 44px auto 50px;
        ion-button{
            --border-radius: 3px;
            border-radius: 3px !important;
            height: 45px;
            --background: #17C257;
            --background-activated: #17C257;
            --background-focused: #17C257;
            color: #fff;
            font-size: 16px;
            font-family: 'nunitoExtraBold';
            box-shadow: 0px 1px 10px #17C257AD;
            border-color: #17C257;
            border-bottom: none;
            width: 100%;
        }
    }

    .smaller-rupee {
        width: 8px !important;
    }

    .call-section {
        background: #e1f7e9;
        width: 100%;
        border-radius: 6px;
        display: flex;
        margin-bottom: 80px;
        flex-direction: column;
        .value-block {
            padding: 10px;
            display: flex;
            flex-direction: column;
            width: 50%;
            text-align: center;
            .text {
                font-size: 16px;
                color: #2C3B32;
            }
            .value {
                font-size: 22px;
                font-family: nunitoSemiBold;
            }
        }
        .total-block{
            width: 100%;
            padding-bottom: 0;
            .text {
                font-size: 17px;
            }
            .value {
                font-size: 24px;
                font-family: nunitoSemiBold;
            }
        }
    }

    .duration-amount-block {
        display: flex;
        flex-wrap: wrap;
    }
}

.custom-searchbar {
    text-align: center !important;
    border-bottom: 2px solid silver;
    --box-shadow: none;
    -webkit-padding-start: 0;
    padding-inline-start: 0;
    padding-inline-start: 0;
    // font-family: nunitoSemiBold;
    color: #000000;
    margin-top: 20px;
    --background: transparent;
}

// dashboard style
.chart-section {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    .chart {
        width: 156px;
        height: 156px;
    }
}
.tab-block {
    margin-top: 32px;
    ion-segment {
        border-bottom: .5px solid #cbcbcb;
        ion-segment-button {
            --color: #586A7A;
            font-family: 'nunitoLight';
            font-size: 12px;
            --color-checked: #2D197F;
            text-transform: unset;
            --indicator-height: 3px;
        }
        .segment-button-checked {
            font-family: 'nunitoBold';
        }
        .items {
            .upper-section {
                display: flex;
                .icon {
                    margin-right: 3px;
                }
            }
            .lower-section {
                display: flex;
                padding-left: 18px;
                font-family: 'nunitoSemiBold';
                color: #212121;
            }
        }
    }
    
}

.search-section {
    background: #F2F4F4;
    // padding: 4px 0 16px;
    padding: 0 0 8px;
    // margin: 16px 0;
    margin: 0;
    border-radius: 8px;
    .custom-searchbar {
        height: 50px;
        width: 50%;
        margin: auto;
    }
}

.search-section-reports {
    background: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .custom-searchbar {
        width: 85%;
    }
    span {
        color: #F37921;
        font-size: 14px;
        font-family: 'nunitoSemiBold';
        text-align: right;
    }
}

.status-icon {
    width: 17px;
    margin-left: 10px;
}

.search-section-changed {
    display: flex;
    padding: 20px 24px 12px;
    justify-content: flex-start;
    align-items: center;
    margin-top: 6px;
    margin-bottom: 6px;
    ion-label {
        font-family: 'nunitoBold';
        font-size: 16px;
        color: #808080;
    }
    ion-segment {
        width: 40%;
        margin: 0 8px;
        ion-segment-button {
            --background-checked: #F37921;
            --border-radius: 8px;
            font-size: 12px;
            --color-checked: #fff;
            min-height: 32px;
            --indicator-height: 0;
            border: 1px solid #808080;
            margin: 0 8px;
            text-transform: capitalize;
            min-width: inherit;
        }
        .segment-button-checked {
            border: 1px solid #f37921;
        }
    }
    .custom-searchbar {
        height: 40px;
        width: 45% !important;
        margin: 0;
    }
    .search-button {
        --background: #593CC7;
        img {
            width: 16px;
        }
    }
}

.search-section-changed-revamped {
    background: #fff;
    border: 1px solid #EFEFEF;
    border-radius: 12px;
    margin-top: 3px;
    margin-bottom: 12px;
    padding: 12px 0 12px 20px;
    .sip-value-block {
        background: #F7F7F7;
        border: 1px solid #EFEFEF;
        min-width: 220px;
        display: flex;
        padding: 12px;
        margin-right: 16px;
        .block {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50%;
            .upper {
                font-size: 12px;
                color: #6E6E6E;
                font-family: 'nunitoSemiBold';
            }
            .lower {
                color: #212121;
                font-family: 'nunitoBold';
                font-size: 14px;
                margin-top: 6px;
            }
        }
        
    }
    ion-label {
        font-family: "nunitoSemiBold";
        font-size: 12px;
        color: #6E6E6E;
        min-width: 62px;
    } 
    ion-segment {
        width: 260px;
        ion-segment-button {
            --background-checked: #FFF4EB;
            --color-checked: #BC7A4A;
        }
        .segment-button-checked {
            border: 1px solid #FDAA6E;
            font-family: 'nunitoSemiBold';
        }
    }
    .custom-searchbar {
        width: calc(100% - 410px) !important;
        border-bottom: 1px solid #E4E7EB !important;
    }
    .search-button {
        --background: transparent;
        --box-shadow: none;
        --background-activated: transparent;
        --background-focused: transparent;
        --background-hover: transparent;
        img {
            width: 24px;
            min-width: 24px;
        }
    }
}

.pointer {
    cursor: pointer !important;
}

.position-relative {
    position: relative !important;
}

.float-right {
    float: right !important;
}

.text-decoration-underline {
    text-decoration: underline !important;
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-visible {
    overflow: visible !important;
}
.cursor-auto {
 cursor: auto !important;
}

.cursor-default {
    cursor: default !important;
   }

// th.active {
//     background: #F37921 !important;
//     color: #fff !important;
// }

.pay-detail-search-section {
    //justify-content: space-between;
    .custom-searchbar {
        height: 32px;
        width: 65% !important;
        margin: 0;
    }
    ion-select {
        font-size: 14px;
        color: #212121;
        font-family: 'nunitoSemiBold';
    }
    .download-block {
        display: flex;
        align-items: center;
        font-family: 'nunitoSemiBold';
        font-size: 13px;
        color: #F37921;
        img {
            width: 7px;
            margin-right: 2px;
        }
    }
}

.detail-table {
    .main-table {
        .desktop-table {
              //harshal
            //max-height: calc(100vh - 286px);
            width: 100%;
        }
        td {
            position: relative;
            .upper-part {
                min-height: 61px;
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: flex-end;
                color: #434343;
               .arrow-button{
                    right:11px !important;
                    position: absolute;
                    top: 23px;
                }
                
               
            }
            .detail-part-fix {
                display: flex;
                align-items: center;
                position: absolute;
                top: 45px;
                z-index: 11;
                font-size: 14px;
                color: #716A90;
                padding: 8px 12px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                background: #F4F3F9;
                width: 1099px;
                min-height: 75px;
                flex-flow: wrap;
                justify-content: flex-start;
                .detail-part {
                    background: #F4F3F9;
                    height: 56px;
                    margin-bottom: 8px;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    .detail-heading {
                        color: #716A90;
                        font-size: 12px;
                        font-family: 'nunitoSemiBold';
                    }
                    .detail-value {
                        color: #212121;
                        font-size: 14px;
                        font-family: nunitoSemiBold;
                    }
                }
            }
        }
        .m-b-72 {
            margin-bottom: 72px;
        }
    }
}

@media screen and (min-width: 768px) {
    .smaller-container {
        width: 700px !important;
    }
    ion-tabs {
        top: 60px !important;
    }
    .smaller-tab-height {
        top: 60px !important; 
    }
    .bigger-tab-height {
        top: 140.8px !important;
    }
    .picker-wrapper {
        bottom: unset !important;
        top: 30vh;
    }
    #custom-modal-5 {
        // width: 1100px;
        .jw-modal {
            width: 750px;
            .toppers_list {
                width: 680px;
                margin-left: 30.5px;
                thead {
                    width: 680px
                }
                table {
                    th, td {
                        max-width: 136px;
                        width: 136px;
                    }
                }
            }
        }
    }
    .login-container {
        .login-field-section {
            margin: auto;
            // width: 400px;
        }

        .mobile-content-section {
            display: none !important;
        }
    }
    .container{
        width: 720px;
        margin: 0 auto;
        position: relative;
        padding: 0 !important;
    }
    .seg-one{
        padding-left: 0 !important;
        ion-segment{
            margin-left: 0;
        }
    }
    .seg-two{
        padding-left: 0 !important;
        ion-segment{
            margin-left: 0 !important;
            ion-segment-button{
                margin-left: 0 !important;
            }
        }
    }
    .search-toolbar {
        --padding-start: 0 !important;
        --padding-end: 0 !important;

    }
    .ion-searchbar{
        text-align: left !important;
        font-family: nunitoBold !important;
        font-size: 30px;
        border-bottom: 2px solid silver !important;
    }
    .edit-block {
        top: 10px;
    }
    .desktop-visibility{
        display: block;
        td, th{
            font-size: 13px;
        }
        .td-block{
            font-size: 13px !important;
        }
    }
    .mobile-visibility{
        display: none;
    }
    .mobile-visibility-flex {
        display: none;
    }
    .mobile-tab-bar-visibility{
        display: none;
    }
    .dashboard-segment{
        ion-segment-button{
            font-size: 13px !important;
        }
    } 
    .profile-block {
        .mobile{
            font-size: 13px !important;
        }
    }
    .tablebot {
        td {
            font-size: 13px !important;
        }
    }
    .desktop-segment-bottom {
        ion-segment-button {
            font-size: 13px !important;
        }
    }
    .destop-segment-middle {
        .segment-button-checked {
            --indicator-height: 0;
            border-bottom: 4px solid #2D197F;
        }
        ion-segment-button {
            --border-radius: 0;
        }
    }

    // .custom-validation-block{
    //     display: none;
    // }
    
}

.search-toolbar-block {
    --min-height: auto;
}



@media screen and (min-width: 992px) {
    .smaller-container {
        width: 840px !important;
    }
    #custom-modal-5 {
        // width: 1100px;
        .jw-modal {
            width: 900px;
            .toppers_list {
                width: 830px;
                margin-left: 30.5px;
                thead {
                    width: 830px
                }
                table {
                    th, td {
                        max-width: 166px;
                        width: 166px;
                    }
                }
            }
        }
    }
    .container{
        width: 960px;
        margin: 0 auto;
        position: relative;
    }
    .edit-block {
        top: 18px;
    }
    .dashboard-segment{
        ion-segment-button{
            font-size: 14px !important;
        }
    } 
    .profile-block {
        .mobile{
            font-size: 14px !important;
        }
    }
    .login-container {
        .mobile-content-section {
            display: none !important;
        }
    }
    
}



@media screen and (min-width: 1200px) {
    .smaller-container {
        width: 1140px !important;
    }
    #custom-modal-5 {
        // width: 1100px;
        .jw-modal {
            width: 1100px;
            .toppers_list {
                width: 1028px;
                margin-left: 30.5px;
                thead {
                    width: 1028px
                }
                table {
                    th, td {
                        max-width: 205.6px;
                        width: 205.6px;
                    }
                }
            }
        }
    }
    .login-container {
        .mobile-content-section {
            display: flex !important;
        }

        .login-field-section {
            margin: unset;
            width: 25% !important;
        }
    }
    .vertical-devider {
        // right: 43% !important;
    }
    .desktop-visibility{
        td, th{
            font-size: 15px;
            // width: 166px !important;
            // max-width: 166px !important;
        }
        .td-block{
            font-size: 15px !important;
        }
        // th:first-child, td:first-child{
        //     width: 270px !important;
        // }
        // th:last-child, td:last-child{
        //     width: 70px !important;
        // }
    }
    .container{
        width: 1140px;
        margin: 0 auto;
        position: relative;
    }
    .dashboard-segment{
        ion-segment-button{
            font-size: 16px !important;
        }
    } 
    .profile-block {
        .mobile{
            font-size: 16px !important;
        }
    }

    .tablebot {
        td {
            font-size: 16px !important;
        }
    }

    .desktop-segment-bottom {
        ion-segment-button {
            font-size: 16px !important;
        }
    }
    .desktop-segment-market {
        ion-segment-button {
            font-size: 15px !important;
        }
    }
}

.no-data-found {
    padding: 10px !important;
    text-align: center !important;
    color: #212121 !important;
    font-family: nunitoRegular !important;
}

.border-right-ddd {
    border-right: 1px solid #dddddd !important;
}

.expiry-date-segment {
    ion-segment-button {
        min-height: unset;
        font-size: 13px;
        color: #716A90;
        letter-spacing: 0;
        text-transform: capitalize;
        min-width: unset;
        --padding-start: 8px;
        --padding-end: 8px;
        --indicator-height:0;
        --color-checked: #2D197F;
        &::part(native) {
            min-width: unset;
        }

    }
    .segment-button-checked {
        font-family: nunitoBold;
    }
}

.detail-part-dashboard {
    width: 100vw;
    left: -16px;
    position: relative;
    .header-part {
        position: relative;
        padding: 11px 16px;
        background: #F2F4F4;
        .heading {
            display: flex;
            justify-content: space-between;
            padding-left: 26px;
            background: #F2F4F4;
            color: #6E6E6E;
            font-size: 12px;
        }
    }
    .listing-part {
        display: flex;
        justify-content: space-between;
        // border-bottom: 1px solid #cbcbcb;
        flex-direction: column;
        .items {
            display: flex;
            justify-content: space-between;
            padding: 20px 16px;
            border-bottom: 1px solid #cbcbcb;
            .left-part {
                display: flex;
                align-items: center;
                img {
                    margin-right: 10px;
                    position: relative;
                    // bottom: 1px;
                }
                .change-value {
                    color: #9C9C9C;
                    font-size: 14px;
                    margin-left: 3px;
                }
                .type {
                    font-size: 16px;
                    color: #000000;
                    font-family: 'nunitoBold';
                }
            }
        }
    }
    th {
        height: 43px;
    }
    th:first-child, td:first-child {
        padding-left: 12px !important;
    }
    
}

.detail-part-aum-inner {
    left: 0;
    .value {
        font-family: 'nunitoSemiBold';
        font-size: 14px;
    }
    .code-block {
        display: flex;
        flex-direction: column;
        .code {
            color: #9C9C9C;
        }
        .name {
            font-size: 16px;
            color: #000;
            font-family: 'nunitoBold';
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 25vw;
        }
    }
    .header-part {
        .heading {
            padding-left: 0;
            span {
                display: flex;
            }
        }
    }
    .listing-part {
        border-bottom: none;
        .items {
            padding: 16px;
            .left-part {
                flex-direction: column;
                max-width: 50%;
                word-break: break-all;
                align-items: flex-start;
                .code {
                    font-size: 14px;
                    color: #9C9C9C;
                    font-family: "nunitoRegular";
                }
                .change-value {
                    color: #000;
                    font-size: 16px;
                    margin-left: 0;
                    font-family: 'nunitoBold';
                }
            }
            .right-part {
                max-width: 50%;
            }
        }
    }
}

.detail-part-mobile {
    table {
        width: 100%;
        td, th {
            text-align: right;
            max-width: 28vw;
        }
        th {
            height: 43px;
            color: #6E6E6E;
            font-size: 12px;
            background: #F2F4F4;
        }
        td {
            padding: 16px 3px;
            color: #212121;
            font-size: 14px;
            font-family: 'nunitoSemiBold';
            .block {
                display: flex;
                flex-direction: column;
                .type {
                    font-size: 16px;
                    color: #000;
                    font-family: 'nunitoBold';
                }
                .time {
                    color: #9C9C9C;
                    font-size: 13px;
                    font-family: 'nunitoRegular';
                }
            }
            
        }
        td:first-child, th:first-child {
            max-width: 46vw;
            padding-left: 16px;
            text-align: left;
        }
        td:last-child, th:last-child {
            padding-right: 16px;
        }
        tbody {
            tr {
                border-bottom: .5px solid #cbcbcb;
            }
        }
    }
    
}

.aum-title {
    height: auto;
    background: url('assets/svg/mobile-header-bg-bigger.svg');
    background-size: cover;
    .icon-title {
        justify-content: space-between;
        width: 100%;
        align-items: flex-start;
        padding-top: 12px;
        ion-icon {
            margin-top: 4px;
        }
    }
    .total-clients-block {
        display: flex;
        margin: 16px 0;
        .item-block {
            margin-right: 40px;
        }
        .top {
            font-size: 14px;
            font-family: 'nunitoLight';
        }
        .lower {
            font-size: 16px;
            font-family: 'nunitoBold';
        }
    }
}

.segment-title-block {
    background: url('assets/svg/mobile-header-bg-bigger.svg');
}

.aum-search {
    background: none;
    margin: 0;
    .custom-searchbar {
        width: calc(100% - 32px);
    }
}

.header-table-parent-toolbar {
    --min-height: 43px;
}

.sip-book {
    ion-segment {
        font-family: 'nunitoLight';
        color: #586A7A;
        justify-content: space-between;
        ion-segment-button {
            flex: 0 1 auto;
        }
        .items {
            min-height: 83px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin-bottom: 4px;
            .upper-section {
                flex-direction: column;
                min-height: 40px;
                justify-content: center;
                .type {
                    font-size: 20px;
                    line-height: 18px;
                }
                .date {
                    font-family: 'nunitoLight';
                }
            }
            .lower-section {
                padding-left: 0;
                color: unset;
                font-family: unset;
                min-width: 164px;
                justify-content: space-between;
                margin-top: 10px;
                .part {
                    display: flex;
                    flex-direction: column;
                    .data {
                        font-size: 13.5px;
                    }
                }
            }
        }
    }
    .segment-button-checked {
        .title {
            font-family: 'nunitoRegular';
        }
        .data {
            font-family: 'nunitoSemiBold';
        }
    }
}

.smaller-title {
    font-size: 14px;
    font-family: nunitoRegular;
}

.orange-button {
    --border-radius: 6px;
    border-radius: 6px;
    height: 50px;
    --background: #F37921;
    --background-activated: #F37921;
    --background-focused: #F37921;
    box-shadow: 0px 2px 10px #F37921AD;
}

.contact-option {
    display: flex;
    justify-content: flex-end;
    .option {
        color: #2D197F;
        font-size: 14px;
        padding: 0 12px;
        display: flex;
        cursor: pointer;
        align-items: center;
        .option-img {
            // width: 10px;
            height: 15px;
        }
        .option-type {
            margin-left: 3px;
        }
    }
}

.info-type {
    position: relative;
    display: inline-block;
  }
  
  .info-type .tooltiptext {
    visibility: hidden;
    width: auto;
    top: 100%;
    left: 30%;
    margin-left: -60px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px;
  
    /* Position the tooltip */
   position: absolute;
    z-index: 999;
  }
  
  .info-type:hover .tooltiptext {
    visibility: visible;
  }

.refresh-reset {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.detail-part-aum-inner-table {
    .header-part {
        .heading {
            span {
                width: 27.5%;
                display: flex;
                justify-content: flex-end;
            }
            span:first-child {
                width: 45%;
                justify-content: flex-start;
            }
            span:nth-child(2) {
                justify-content: center;
            }
        }
    }
    .listing-part {
        .items {
            .left-part {
                width: 27.5%;
                max-width: 27.5%;
                text-align: center;
                justify-content: center;
                align-items: center;
            }
            .right-part {
                width: 27.5%;
                max-width: 27.5%;
                text-align: right;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                word-break: break-all;
            }
            .left-part:first-child {
                width: 45%;
                max-width: 45%;
                text-align: left;
                align-items: flex-start;
            }
        }
    }
}

.no-data-row {
    .no-data-found:first-child {
        text-align: center !important;
    }
}

.right-arrow {
    transform: rotate(-90deg);
    left: 5px;
    position: relative;
    top: 1px;
    color: #757382;
    width: 9px;
}

 // generate-otp

ion-modal.generate-otp::part(content) { 
    min-height:247px !important;
    height:200px;
    width:387px !important;
}
ion-modal.generate-otp {
    .ion-page {
        padding: 30px 50px 20px;
        .close{
            right:-37px !important;
        }
    }
}
//  .generate-otp{
//     .modal-wrapper {
//         min-height:247px !important;
//         height:200px;
//         width:387px !important;
//         .ion-page {
//             padding: 30px 50px 20px;
//             .close{
//                 right:-37px !important;
//             }
//         }
//     }
// }

// css changed for modal-wrapper	
ion-modal.popup-text::part(content) {
    height:110px !important;
}
ion-modal.popup-text{
    .ion-page {
        padding: 20px;
        .message{
            font-size: 16px !important;
            font-family: 'nunitoSemiBold';
        } 
        .close{
            right:-12px;
            top:-25px;
        }
        .title{
            display:none;
        }
    }
}

// .thankyou_model .modal-wrapper{width:500px !important ;height: 274px!important; border-radius: 15px; position: relative;}
ion-modal.thankyou_model::part(content) {
    width:500px !important ;height: 274px!important; border-radius: 15px; position: relative;
}
ion-modal.account_activation::part(content) {
    max-width: 900px !important;
    width: 900px !important;
    height: calc(85vh - 50px);
    border-radius: 8px;
}
ion-modal.account_activation{
    .ion-page {
        padding: 0px 0px 15px!important;
        justify-content: flex-start;
    }
}

// .account_activation .modal-wrapper {
//     max-width: 900px !important;
//     width: 900px !important;
//     height: calc(70vh - 50px);
//     border-radius: 8px;

//     .ion-page {
//         padding: 0px 0px 15px!important;
//         justify-content: flex-start;
//     }
       

// }

ion-modal.callforsupport::part(content) { 
    max-width: 900px !important;
    width: 500px !important;
    height: 500%;
    border-radius: 8px;
}
ion-modal.callforsupport {
    .ion-page {
        padding: 0px 0px 35px!important;
    }
}

// .callforsupport .modal-wrapper {
//     max-width: 900px !important;
//     width: 500px !important;
//     height: 500%;
//     border-radius: 8px;

//     .ion-page {
//         padding: 0px 0px 35px!important;
//     }
       

// }

ion-modal.mf-popup{
    // .modal-wrapper {             review
        .ion-page {
            .message{
                margin-top: 10px;
                text-align: center;
            } 
        
        }
        //height:115px !important;
    // }                            review  
}

@media (min-width:501px) and (max-width:767px){
    .title-block-custom{
        background: none;
    }
}

@media screen and (max-width: 500px){
    .ion-card-parent-mobile {
        background: url('assets/svg/upper_bg_mobile.svg') no-repeat fixed left top, url('assets/svg/bottom_bg_mobile.svg') no-repeat fixed right bottom;
    }
    .revamp-drop-down {
        .business-monitor-block {
            .select-block {
                width: calc(100vw - 80px);
            }
        }
        .hierarchy-wrapper {
            width: 95vw;
            margin: 0 8px;
            left: -60px;
            top: 0;
            top: 70px;
            padding-right: 20px;
        }
        .add-hierarchy-height {
            height: calc(100vh - 271px);
            max-height: calc(100vh - 271px);
            padding-right: 20px;
            padding-top: 20px !important; z-index: 10600 !important;
        }
    }
    
    .inner-content {
        // height: calc(100vh - 417px);
        // height:  calc(100vh - 465px);
        height: calc(100vh - 252px);
    }
    .search-section-changed-revamped {
        padding: 0;
        width: 100vw;
        position: relative;
        left: -8px;
        border-radius: 0;
        margin-bottom: 2px;
        .sip-value-block {
            margin-right: 0;
        }
    }
    .web-equity {
        .header-table {
            th {
                font-size: 12px;
                .updated-sorting-img {
                    margin-left: 2px !important;
                }
            }
            th:last-child {
                padding-right: 7px !important;
            }
        }
    }
    .detail-part-dashboard {
        width: 100%;
        .header-part {
            border-radius: 10px;
        }
    }
    .detail-part-dashboard.detail-part-aum-inner {
        // max-height: calc(100vh - 463px);
        // overflow: auto;
        .main-table {
            max-height: unset !important;
        }
    }
    .max-height-unset-in-mobile {
        max-height: unset !important;
    }
    .dashbaord-container {
        // top: -85px;
        top: unset;
        padding: 0 8px;
    }
    .common-header-all {
        height: 184px !important;
        height: unset !important;
        .header-bg {
            position: absolute;
            background: #F7F7F7;
            height: 164px !important;
            width: 100vw;
        }
        .back-drop-down-section {
            padding-left: 16px;
            margin-top: 8px;
        }
    }
    .common-content-section {
        .card-type-segment {
            flex-wrap: nowrap !important;
            width: auto;
            overflow: auto;
            ion-segment-button {
                flex: 0 1 auto;
                width: unset;
                // min-width: 45vw !important;
                // min-width: unset !important;
                min-width: 201px !important;
            }
        }
    }
    .hide-in-mobile {
        display: none !important;
    }
    .segment-search-block {
        padding: 0 16px;
        flex-direction: column;
        .header-segment-top {
            justify-content: center;
            margin: 0;
            ion-segment-button {
                height: 32px;
            }
        }
        ion-searchbar {
            width: 100%;
            margin-top: 4px;
        }
    }
    .ng-dropdown-panel .ng-dropdown-panel-items {
        max-height: 276px !important;
    }
    .myDpSelectorAbsolute {
        margin-top: 21px !important;
        position: fixed !important;
        width: 100% !important;
        top: 128px !important;
        height: 56vh !important;
    }
    .myDpSelectorArrow:after, .myDpSelectorArrow:before {
        visibility: hidden !important;
    }
    .support-align {
        td:first-child {
            vertical-align: text-bottom !important;
        }
    }

   
    ion-searchbar {
        .searchbar-input-container {
            .searchbar-input {
                // padding-inline-start: 6px;
                // padding-inline-end: 6px;
                font-size: 14px;
            }
        }
    }
    .custom-searchbar {
        border-bottom: 1px solid silver;
        height: 40px;
    }

    .search-section-reports {
        margin-top:10px;
        background: unset;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .custom-searchbar {
            width: 90%;
            margin:0 auto;
        }
      
    }
    .search-section-changed {
        background: transparent;
        margin: 0;
        flex-direction: column;
        padding: 16px;
        .upper-part {
            display: flex;
            align-items: center;
            width: 100%;
            ion-segment {
                width: calc(100vw - 110px);
                margin: 0;
            }
        }
        .lower-part {
            display: flex;
            margin-top: 16px;
            width: 100%;
            align-items: center;
            .custom-searchbar {
                width: calc(100vw - 95px) !important;
                margin-right: 16px;
            }
        }
    }
    .indices {
        .block {
            width: 100%;
        }
    }
    .reset-section-mobile {
        display: flex;
        align-items: center;
        cursor: pointer;
        .reset-block {
            display: flex;
            align-items: center;
            .reset-img {
                width: 18px;
            }
            .reset {
                margin-left: 8px;
                font-size: 16px;
            }
        }
    }
    .calculator {
        .form-block {
            width: 100%;
            padding: 0 16px;
        }
        .login-btn {
            margin: 46px auto;
            ion-button {
                height: 60px;
            }
        }
        .field-block {
            ion-item {
                .field {
                    margin-top: 22px !important;
                    ion-input {
                        font-size: 16px;
                        height: 40px;
                    }
                }
            }
            ion-item:first-child {
                .field {
                    margin-top: 0px !important;
                }
            }
        }
        .call-section {
            width: calc(100% - 32px);
            margin: 24px auto;
            .value-block {
                .value {
                    font-size: 15px;
                    font-family: nunitoSemiBold;
                }
                .text {
                    font-size: 12px;
                }
            }
            .total-block {
                .text {
                    font-size: 14px;
                }
                .value {
                    font-size: 19px;
                }
            }
        }
    }
    .width-100-vw {
        width: 100vw !important;
    }
    .mobile-visibility-tbody {
        display: table-row-group;
    }
    .select-popover {
        .popover-content {
            width: 83vw;
        }
    }
    // session expired pop up
    ion-modal.session-expired::part(content) {
        height: 100%;
    }
    ion-modal.session-expired {
        .ion-page {
            height: 420px;
        }
    }

    // css changed for modal-wrapper	
    ion-modal.agree::part(content) { 
        height: 98%;
        width: 96%;
    }
    ion-modal.agree {
        iframe{
            height: 75vh;
            width: 98%;
        }
    }

    .switch {
        height: 22px;
    }
    .snapshot-segment {
        margin: 0;
        ion-segment-button {
            --padding-start: 10px;
            --padding-end: 10px;
            font-size: 13px;
        }
        .segment-button-checked {
            font-size: 13px;
        }
    }
ion-modal.superstars::part(content) { 
    // .superstars {
    //     .modal-wrapper {
            margin: 0 16px !important;
            height: 100% !important;
            background: transparent;
            border-radius: unset !important;
    }
    ion-modal.superstars{
            .ion-page {
                border-radius: 8px 8px 0 0 !important;
                padding: 16px 30px 35px !important;
                height: 507px;
                background: #fff;
                top: unset;
                .view-more {
                    font-size: 16px !important;
                    cursor: pointer;
                }
                .header-table {
                    margin-top: 40px !important;
                }
                .body-table {
                    tr {
                        border-bottom: 1px solid #CBCBCB !important;
                    }
                }
                .table-wrapper {
                    height: 280px !important;
                }
                .close {
                    top: -10px;
                }
            }
        // }
    }
    ion-modal.support-modal-height .ion-page {
        top: calc(100% - 404px) !important;
        height: 100% !important;
    }
ion-modal.details-modal::part(content) {    
    margin: 0 0 !important;
    height: 100% !important;
    background: transparent;
    border-radius: unset !important;
}
ion-modal.details-modal {        
    .ion-page {
        border-radius: 8px 8px 0 0 !important;
        padding: 26px 30px 35px !important;
        height: 300px;
        background: #fff;
        top: unset;
        padding-right: 2px !important;
        padding-bottom: 0 !important;
        .close {
            top: -21px;
            right: 14px;
            color:#8D8989 !important;
        }
        .items {
            padding: 16px 0;
            .item {
                display: flex;
                color: #282828;
                font-size: 12px;
                padding: 8px 0;
                .type {
                    width: 45%;
                }
                .value {
                    font-family: 'nunitoSemibold';
                    width: 65%;
                    padding-left: 6px;
                    position: relative;
                }
                .value::before {
                    content: ":";
                    position: absolute;
                    left: 0;
                    top: -1px;
                }
            }
            .item:last-child {
                font-family: 'nunitoBold';
            }
            .total {
                font-family: 'nunitoBold';
                .value {
                    font-family: 'nunitoBold';
                }
            }
        }
    }
}

   
       
// css changed for modal-wrapper	
ion-modal.client-details{
    .ion-page {
        padding: 16px 3px 35px !important;
        ion-header {
            .client-details-heading {
                margin: 30px 0 0 0px;
            }
        }
        ion-content {
            .details-container {
                padding: 0 10px;
            }
        }
    }
}
    .account_activation {
        // .modal-wrapper {
            .ion-page {
                padding: 0px 0px 35px !important;
                ion-header {
                    .client-details-heading {
                        margin: 30px 0 0 0px;
                    }
                }
                ion-content {
                    .details-container {
                        padding: 0 10px;
                    }
                }
            }
        // }
    }

    .generate-otp{
        // .modal-wrapper {
            .ion-page {
                padding: 30px 50px 20px;
                height:230px !important;
                .close{
                    right:-16px !important;
                }
            }
        // }
    }
    .jv-request{
        // .modal-wrapper {
            .ion-page {
                border-radius: 8px 8px 0 0 !important;
                padding: 16px 30px 35px !important;
                height: 507px;
                background: #fff;
                top: unset;
            }
        // }
    }         
// css changed for modal-wrapper
    ion-modal.cancel-request {
        .ion-page {
            height: 164px;
        }
        
    }

    ion-modal.jv-approveRejectRes-request {
        .ion-page {
            height: 164px; width: 95% !important;  position: absolute;  height: 400px !important; margin: 0 auto; bottom: 0 !important; top : inherit !important; border-radius: 15px 15px 0 0;
        }
        
    }

    .jv-request{
        // .modal-wrapper {
            .ion-page {
                height: 277px;
            }
        // }
        
    }

// css changed for modal-wrapper	
ion-modal.ipo-modal::part(content) { 
    max-width: 410px;  
    height: 100%;
    background: transparent;
    border-radius: unset !important;
}

ion-modal.ipo-modal {
    ion-backdrop {
        opacity: .7 !important;
    }            
    .ion-page {
        border-radius: 8px 8px 0 0 !important;
        padding: 16px 30px 35px !important;
        // height: 230px;
        height: 272px;
        background: #fff;
        top: unset;
        h5:not(:last-child){
            border-bottom: 1px solid #eee;
            margin-top: 10px;
            padding-bottom: 10px;
        }
        h5{
            color: #2680EB;
            cursor: pointer;
        }
        .close {
            position: absolute;
            right: -16px;
            top: -20px;
            color: #C1C4CC;
            font-family: nunitoExtraBold;
            font-size: 23px;
            cursor: pointer;
        }
        .title {
            display: flex;
            justify-content: center;
            color: #000;
            font-family: nunitoExtraBold;
            font-size: 16px;
            span {
                border-bottom: 3px solid var(--ion-red-secondary-color);
                padding: 0 5px 2px;
            }
        }
    }
}

// css changed for modal-wrapper
ion-modal.no-data-modal {
    .ion-page {
        padding: 16px 30px 0px !important;
        height: 87px;
    }
}

    ion-modal.business-opportunities::part(content) { 
    // .business-opportunities {
    //     .modal-wrapper {
            max-width: 960px;  
            width: 960px;
            // height: 650px;
            height: 92vh;
            border-radius: 8px;
            margin: 0 1px !important;
    }
    ion-modal.business-opportunities{
            .ion-page {
                height: 86vh;
                padding: 0 !important;
                // .header-table {
                //     margin-top: 23px;
                // }
                ion-header {
                    // background: #F7F7F7;
                    background: transparent;
                    height: 110px;
                    height: unset;
                    .close {
                        right: 16px;
                        color: #8D8989;
                        top: 0px;
                        font-family: 'nunitoRegular';
                        font-size: 26px;
                        position: absolute;
                        cursor: pointer;
                    }
                    .title {
                        font-size: 18px;
                        color: #212121;
                        font-family: 'nunitoSemiBold';
                        text-align: left;
                        display: block;
                        // padding: 9px 16px;
                        padding: 9px 32px 9px 16px;
                        background: #F7F7F7;
                        span {
                            font-family: 'nunitoExtraBold';
                            border-bottom: none;
                        }
                    }
                }
                ion-content {
                    // top: -57px;
                    z-index: 999;
                    width: 99.8%;
                    --background: transparent;
                    .top-section {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        .common-content-section {
                            .card-type-segment {
                                margin-bottom: 0;
                                width: 100vw;
                                ion-segment-button {
                                    --indicator-height: 0;
                                    flex: 0 1 auto;
                                    // min-width: 46vw;
                                    // width: 46vw;
                                    width: unset;
                                    min-width: unset !important;
                                    .card {
                                        padding: 12px;
                                        .title {
                                            font-size: 13.5px;
                                            color: #797EAC;
                                            font-family: 'nunitoSemiBold';
                                        }
                                        .value {
                                            font-size: 15.5px;
                                        }
                                        .card-body {
                                            .card-column {
                                                .card-value {
                                                    overflow: auto;
                                                    text-overflow: ellipsis;
                                                    max-width: 16vw;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .filter-section {
                            padding: 0;
                            border: none;
                            background: transparent;
                            // margin-bottom: 16px;
                            margin: 0;
                            padding: 10px 16px;
                            // overflow: auto;
                            width: 100%;
                            ion-segment {
                                // width: unset;
                                width: 95vw;
                                ion-segment-button {
                                    border-radius: 16px;
                                    min-height: 30px;
                                    font-size: 11px;
                                    margin: 0 2px;
                                }
                            }
                        }
                    }
                    .inner-content{
                        height: calc(100vh - 305px);
                    }
                    .search-section-changed-revamped {
                        margin: 8px 6px;
                        padding: 0;
                        border: none;
                        .switch-block {
                            margin-top: 8px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            ion-label {
                                margin-right: 8px;
                            }
                            .switch {
                                width: 100px;
                                height: 24px;
                                .off {
                                    right: -24%;
                                }
                                .slider:before {
                                    left: 5px;
                                    bottom: 2.5px;
                                }
                                input:checked + .slider:before {
                                    transform: translateX(69px);
                                }
                            }
                        }
                        .search-button-block {
                            display: flex;
                            margin-top: 8px;
                            width: calc(100vw - 16px);
                            .custom-searchbar {
                                // width: unset !important;
                                margin: 0;
                                width: calc(100% - 50px) !important;
                            }
                            .search-button {
                                &::part(native) {
                                    padding-right: 0;
                                }
                            }
                        }
                    }
                    .web-equity {
                        margin: 0 6px;
                        .main-table {
                            max-height: unset;    
                            height: unset;
                        }
                        .header-table {
                            margin-top: 23px !important;
                            th {
                                width: 23% !important;
                                padding: 12px !important;
                            }
                            th:nth-child(2) {
                                text-align: right;
                            }
                            
                        }
                        td {
                            width: 23% !important;
                            padding: 12px !important;
                            .data-block {
                                display: flex;
                                flex-direction: column;
                                .name {
                                    color: #0066CC;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                        }
                        td:nth-child(2) {
                            text-align: center !important;
                        }
                    }
                    // .inner-content {
                    //     height: calc(100vh - 288px);
                    // }
                }
            }
        // }
    
    }
    
ion-modal.indices-details::part(content) {
    height: 100%;
    border-radius: 0px;
    margin: 0 !important;
    max-height: 100% !important;
}

ion-modal.indices-details {
    .ion-page {
        height: 100%;
        border-radius: 0 !important;
        padding: 0 0 0 0 !important;
        .close {
            display: none;
        }
        .graph-section {
            padding: 0 16px;
            padding-top: 16px;
        }
        .title {
            justify-content: flex-start;
            color: #fff;
            text-transform: capitalize;
            font-size: 24px;
            font-family: 'nunitoBold';
        }
        .header-table {
            margin-top: 8px !important;
            thead {
                th:first-child {
                    border-radius: 0;
                }
                th:last-child {
                    border-radius: 0;
                }
                th:nth-child(2) {
                    width: unset;
                }
            }
        }
        .table-wrapper {
            height: unset !important;
            max-height: unset !important;
            .body-table {
                tr {
                    td {
                        max-width: 32vw !important;
                    }
                }
            }
        }
    }
}

    

        // support popup

ion-modal.support::part(content) { 
    margin: 0 !important;
}
ion-modal.support {
    .ion-page {
        padding: 16px 0 35px !important;
        .table-wrapper {
            // height: 316px;
            height: unset !important;
            overflow: auto;
            padding: 8px 16px;
        }
        .body-table {
            margin-top: 12px;
            tr {
                td {
                    padding: 12px 0 !important;
                }
            }
        }
        .title {
            span {
                padding: 0 0 2px;
            }
        }
        .close {
            right: 13px;
            color:#8D8989 !important;
        }
        .block {
            display: flex;
            flex-direction: column;
            .type {
                font-size: 14px;
                font-family: 'nunitoSemiBold';
            }
            .detail {
                font-size: 12px;
                color: #9C9C9C;
            }
        }
        .mobile-listing {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            color: #2680EB;
            font-family: 'nunitoBold';
        }
    }
}
// .support {
//     .modal-wrapper {
//         margin: 0 !important;
//         .ion-page {
//             padding: 16px 0 35px !important;
//             .table-wrapper {
//                 // height: 316px;
//                 height: unset !important;
//                 overflow: auto;
//                 padding: 8px 16px;
//             }
//             .body-table {
//                 margin-top: 12px;
//                 tr {
//                     td {
//                         padding: 12px 0 !important;
//                     }
//                 }
//             }
//             .title {
//                 span {
//                     padding: 0 0 2px;
//                 }
//             }
//             .close {
//                 right: 13px;
//                 color:#8D8989 !important;
//             }
//             .block {
//                 display: flex;
//                 flex-direction: column;
//                 .type {
//                     font-size: 14px;
//                     font-family: 'nunitoSemiBold';
//                 }
//                 .detail {
//                     font-size: 12px;
//                     color: #9C9C9C;
//                 }
//             }
//             .mobile-listing {
//                 display: flex;
//                 flex-direction: column;
//                 align-items: flex-end;
//                 color: #2680EB;
//                 font-family: 'nunitoBold';
//             }
//         }
//     }
// }

// add user popup for invest page
// css changed for modal-wrapper
ion-modal.add-user::part(content) {
    margin: 0 !important;
    height: 100% !important;
    background: transparent;
    border-radius: unset !important;
}
ion-modal.add-user {
    .ion-page {
        border-radius: 8px 8px 0 0 !important;
        padding: 16px 16px 0px !important;
        height: 438px;
        background: #fff;
        top: calc(100% - 438px);
        .user-block {
            flex-direction: column;
            .user-item {
                flex-direction: row;
                justify-content: space-between;
                border-bottom: 1px solid #f2f4f4;
                padding: 16px 0;
                .icon-user-block {
                    display: flex;
                    align-items: center;
                    .icon {
                        width: 26px;
                    }
                    span {
                        margin-left: 12px;
                    }
                }
                .arrow {
                    img {
                        width: 6px;
                    }
                }
            }
        }
        .close {
            top: -10px;
            right: -4px;
        }
    }
}

ion-modal.contract-info {
    .ion-page {
        border-radius: 8px 8px 0 0 !important;
        padding:16px 0 0 0 !important;
        height: 560px;
        background: #fff;
        top: unset;
        // .view-more {
        //     font-size: 16px !important;
        // }
        // .header-table {
        //     margin-top: 40px !important;
        // }
        // .body-table {
        //     tr {
        //         border-bottom: 1px solid #CBCBCB !important;
        //     }
        // }
        .table-wrapper {
            height: unset !important;
            padding:0 16px;
        }
        // .close {
        //     top: -10px;
        // }
    }
}
    
    // css changed for modal-wrapper	
    ion-modal.add-to-watchlist::part(content) { 
        margin: 0 16px !important;
        height: 100% !important;
        background: transparent;
        border-radius: unset !important;
    }
    ion-modal.add-to-watchlist {
        .ion-page {
            border-radius: 8px 8px 0 0 !important;
            padding: 16px 8px 20px !important;
            height: 446px;
            background: #fff;
            top: calc(100% - 446px);
            .thin-scroll::-webkit-scrollbar {
                width: 1px;
                background-color: #F5F5F5;
            }
            .table-wrapper {
                max-height: 205px;
            }
            .header-table {
                thead {
                    th {
                        padding: 16px 12px !important;
                    }
                }
            }
            .body-table {
                tr {
                    td {
                        padding: 24px 0px !important;
                    }
                }
            }
            .view-more {
                font-size: 16px !important;
            }
            .header-table {
                margin-top: 40px !important;
            }
            .body-table {
                tr {
                    border-bottom: 1px solid #CBCBCB;
                }
            }
            // .table-wrapper {
            //     height: 280px !important;
            // }
            .close {
                top: -10px;
                right: 6px;
            }
        }
    }

    ion-modal.successfull-popup::part(content) { 
        height: 100% !important;
    }
    ion-modal.successfull-popup {
        ion-backdrop {
            opacity: .6 !important;
        }
        .ion-page {
            height: 220px !important;
            top: unset !important;
            border-radius: 10px 10px 0 0 !important;
        }
    }

    // .successfull-popup {
    //     ion-backdrop{
    //         opacity: .6 !important;
    //     }
    //     .modal-wrapper {
    //         height: 100% !important;
    //         .ion-page {
    //             height: 220px !important;
    //             top: unset !important;
    //             border-radius: 10px 10px 0 0 !important;
    //         }
    //     }
    // }
    .inner-tr-style {
        span:nth-child(2) {
            max-width: 120px !important;
        }
    } 

    .add-user-block-mobile {
        width: 100% !important;
        margin: 0 !important;
        max-width: none !important;
        justify-content: space-around !important;
        padding: 20px 10px !important;
    }
    
    .vertical-devider {
        top: 66px !important;
        height: 66% !important;
    }
    .header-segment-bottom { 
        --background: transparent !important;
        border-radius: 0 !important;
        .segment-button-checked {
            border-bottom: 4px solid #2D197F !important;
            border-radius: 0 !important;
        }
    }
    .mobile-block{
        // overflow: auto;
        max-height: calc(100vh - 341px) !important;
        border-bottom: 1px solid #f2f4f4 !important;
        border-radius: 0 !important;
    }
    .content-block {
        max-height: none !important;
        .ltp-block {
            flex-direction: column !important;
            border-bottom: none !important;
            .ltp-section {
                width: 100% !important;
                padding: 0 16px !important;
                .ltp-heading-section {
                    margin-right: 0 !important;
                    .detail-block {
                        min-width: auto !important;
                    }
                }
            }
            .table-block {
                width: 100% !important;
                padding: 16px !important;
            }
            .common-body-table{
                max-height: none !important;
            }
        }
        .advance-chart-block, .chart-img {
            padding: 0 16px !important;
        }
        
    }
    .login-container{
        padding: 0 !important;
    }

    .login-field-section{
        width: 100% !important;
        height: 100% !important;
        min-width: 380px;
        margin: 0 !important;
        border-radius: 0 !important;
        box-shadow: none !important;
    }

    .login-btn {
        ion-button {
            height: 56px;
            width: 221px;
            margin: auto;
            font-size: 18px;
        }
    }

    ion-modal.popup-text{
        // .modal-wrapper {                              review
           // height:100px !important;
             .ion-page {
                height: 111px;
                padding: 22px !important;
                .close{
                    top:-18px;
                }
            }
        // }
    }
}


@media screen and (max-width: 425px){
    .ltp-detail-section {
        grid-template-columns: 1fr 1fr 1fr 1fr !important;
        .detail-block {
            .title {
                font-size: 10px !important;
            }
        }
    }
    
    .first-column {
        .first-span {
            white-space: nowrap;
            // width: 143px;
            font-size: 16px !important;
            overflow: hidden;
            max-width: 31vw;
            text-overflow: ellipsis;
            padding-right: 3px;
        }
    }
    .spanStyle {
        font-size: 11px !important;
    }
    .inner-tr-style {
        span:nth-child(1) {
            font-size: 12px !important;
        }

        span:nth-child(2) {
            // max-width: 32vw !important;
            max-width: 22vw !important;
            font-size: 14px !important;
        } 
    }
    .tablebot {
        td:nth-child(1)  {
            // max-width: 28vw !important;
            font-size: 12px !important;
        }
        td:nth-child(2)  {
            max-width: 36vw !important;
            font-size: 12px !important;
        }
        td:nth-child(3)  {
            max-width: 24vw !important;
            font-size: 12px !important;
        }
    }
}

@media screen and (max-width: 375px) {
    .ng-dropdown-panel .ng-dropdown-panel-items {
        max-height: 235px !important;
    }
    .detail-part-dashboard {
        .listing-part {
            .items {
                .left-part {
                    .change-value {
                        color: #9C9C9C;
                        font-size: 12px;
                        margin-left: 1px;
                    }
                }
            }
        }
    }
    .calculator {
        .field-block {
            ion-item {
                .field {
                    margin-top: 22px !important;
                    ion-input {
                        font-size: 13px;
                        height: 40px;
                    }
                }
            }
            ion-item:first-child {
                .field {
                    margin-top: 0px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 365px){
    ion-searchbar {
        .searchbar-input-container {
            .searchbar-input {
                padding-inline-end: 20px;
                font-size: 14px;
            }
        }
    }
    .snapshot-segment {
        margin: 0;
        ion-segment-button {
            --padding-start: 8px;
            --padding-end: 8px;
            font-size: 11px;
        }
        .segment-button-checked {
            font-size: 11px;
        }
    }
    .ltp-detail-section {
        .detail-block {
            .title {
                font-size: 9px !important;
            }
        }
        
    } 
    .login-fields {
        padding-left: 35px !important;
        // .field-block ion-item {
        //     width: calc(100vw - 92px) !important;
        // }
    }
    .first-column {
        .first-span {
            white-space: nowrap;
            // width: 143px;
            font-size: 11px !important;
            overflow: hidden;
            max-width: 31vw;
            text-overflow: ellipsis;
        }
    }
    .spanStyle {
        font-size: 10px !important;
    }
    .inner-tr-style {
        span:nth-child(1) {
            font-size: 10px !important;
        }

        span:nth-child(2) {
            // max-width: 32vw !important;
            max-width: 22vw !important;
            font-size: 11px !important;
        } 
    }
    .tablebot {
        td:nth-child(1)  {
            // max-width: 28vw !important;
            font-size: 11px !important;
        }
        td:nth-child(2)  {
            max-width: 36vw !important;
            font-size: 11px !important;
        }
        td:nth-child(3)  {
            max-width: 24vw !important;
            font-size: 11px !important;
        }
    }
}

.textaligntoast{
    text-align: center;
}

.date-picker-block{

    date-range-picker {
        background: transparent;
        border: none;
        box-shadow: none;
    }
    .daterangepicker-wrapper{
        position: relative;
        border: none;
    }
    
    .daterangepicker {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
        font-size: 14px;
        position: absolute;
        // top: 44px;
        top: unset;
        bottom: 30px;
        background: #fff;
    }
    
    .daterangepicker.always-open{
        top: 0;
        box-shadow: none;
    }
    
    .daterangepicker.open-right{
        right: 0;
    }
    
    .daterangepicker.open-left{
        left: 0;
    }
    
    .daterangepicker.open-center{
        right: -50%;
    }
    
    .daterangepicker.tooltip-chevron:before{
        content: '';
        height: 0px;
        width: 0px;
        border: 10px solid transparent;
        position: absolute;
        border-bottom-color: #aaa; 
        top: -20px;
        display: none;
    }
    
     .daterangepicker.tooltip-chevron:after{
        content: '';
        height: 0px;
        width: 0px;
        border: 9px solid transparent;
        position: absolute;
        border-bottom-color: #fff;
        // top: -18px;
        top: unset;
        bottom: -18px;
        transform: rotate(180deg);
        display: none;
    }

    .dateRangePicker-input {
        opacity: .1;
        position: relative;
        bottom: 33px;
        cursor: pointer;
    }
    
    .daterangepicker.open-left.tooltip-chevron:before{
        left: 10px;
    }
    
    .daterangepicker.open-left.tooltip-chevron:after{
        left: 11px;
    }
    
    .daterangepicker.open-right.tooltip-chevron:before{
        right: 10px;
    }
    
    .daterangepicker.open-right.tooltip-chevron:after{
        right: 11px;
    }
    
     .daterangepicker.open-center.tooltip-chevron:before{
        left: 50%
    }
    
    .daterangepicker.open-center.tooltip-chevron:after{
        left: 50%;
    }
    
    @media (min-width: 550px) {
        .daterangepicker {
            width: 550px;
        }
    }
    
    @media (max-width: 550px) {
        .daterangepicker {
            width: 270px;
        }
        .text-center .pull-right {
            float: none !important;
        }
        .col-md-6 {
            width: 100% !important;
        }
        .col-md-10 {
            width: 100% !important;
        }
        .ranges > div {
            display: none;
        }
    }
    
    .singledatepicker {
        width: 225px;
    }
    
    .daterangepicker {
        z-index: 3000;
        border-radius: 4px;
        box-shadow: 0px 2px 2px 2px #ddd;
        border: 1px solid #aaa;
        padding: 10px;
    }
    
    .daterangepicker div[class*="col-md-"],
    .daterangepicker span[class*="col-md-"] {
        padding: 0 15px 0 5px;
    }
    
    .hidden {
        display: none !important;
        visibility: false !important;
    }
    
    .daterangepicker .calendar {
        margin: 4px;
        float: left;
        border-radius: 4px !important;
        padding: 0 5px 0 5px;
    }
    
    .applyBtn {
        margin: 4px;
    }
    
    .daterangepicker .flush {
        padding: 0 !important;
        margin: 0 !important;
    }
    
    .daterangepicker .flussh {
        padding: 0 !important;
    }
    
    .daterangepicker .flush-bottom {
        padding-bottom: 0 !important;
    }
    
    .daterangepicker .flush-left {
        padding-left: 0 !important;
    }
    
    .daterangepicker .flush-right {
        padding-right: 0 !important;
    }
    
    .daterangepicker .nudge-half--left {
        padding-left: 4px !important;
    }
    
    .daterangepicker .nudge-half--right {
        padding-right: 4px !important;
    }
    
    .daterangepicker .nudge-top {
        top: 5px;
    }
    
    .daterangepicker .push-bottom {
        margin-bottom: 10px;
    }
    
    .daterangepicker th {
        margin: 1px !important;
        padding: 1px !important;
        text-align: center;
        border-radius: 4px !important;
    }
    
    .daterangepicker td {
        font-size: 14px;
        height: 20px;
        width: 20px;
        text-align: center;
        margin: 1px !important;
        padding: 3px !important;
        border-radius: 4px !important;
        white-space: nowrap;
        text-align: center;
    }
    
    .daterangepicker .btn.btn-flat {
        border: none;
        background: transparent;
        margin: 3px !important;
        padding: 1px !important;
    }
    
    .daterangepicker .off {
        color: #666;
        position: initial;
        transform: none;
        top: 0;
        // left: 50%;
        font-size: 12px;
        font-family: nunitoExtraBold;
        right: 0;
    }
    
    .daterangepicker table {
        border-spacing: 0;
        border-collapse: collapse;
    }
    
    .daterangepicker td,
    .daterangepicker th {
        padding: 0;
    }
    
    .daterangepicker .clickable {
        cursor: pointer;
    }
    
    .daterangepicker .clickable-link {
        color: #337ab7;
    }
    
    .daterangepicker .clickable.disabled {
        pointer-events: none;
        color: #AAA;
        opacity: 0.5;
        cursor: not-allowed;
    }
    
    .ranges{
        padding: 5px 0;
    }
    
    .ranges .clickable {
        margin-top: 8px !important;
    }
    
    .daterangepicker label {
        display: inline-block;
        max-width: 100%;
        margin-bottom: 5px;
        font-weight: bold;
    }
    
    .daterangepicker .form-control{
        margin: 5px;
    }
    
    .daterangepicker .btn-link {
        padding: 1px 6px 1px 6px !important;
    }
    
    .daterangepicker .bootstrap-flush{
        margin: 0 !important;
        padding: 0 !important;
    }
    
    .daterangepicker .time-picker span{
        padding-left: 4px;
        padding-right: 4px;
    }
    
    .daterangepicker .time-picker .time-breadcrumb{
        position: relative;
        top: 22px;
        font-weight: bolder;
        font-size: 0.8em;
    }
    
    .daterangepicker .col-md-1,
    .daterangepicker .col-md-2,
    .daterangepicker .col-md-3,
    .daterangepicker .col-md-4,
    .daterangepicker .col-md-5,
    .daterangepicker .col-md-6,
    .daterangepicker .col-md-7,
    .daterangepicker .col-md-8,
    .daterangepicker .col-md-9,
    .daterangepicker .col-md-10,
    .daterangepicker .col-md-11,
    .daterangepicker .col-md-12 {
        position: relative;
        float: left;
    }
    
    .daterangepicker .col-md-12 {
        width: 100%;
    }
    
    .daterangepicker .col-md-11 {
        width: 91.66666667%;
    }
    
    .daterangepicker .col-md-10 {
        width: 83.33333333%;
    }
    
    .daterangepicker .col-md-9 {
        width: 75%;
    }
    
    .daterangepicker .col-md-8 {
        width: 66.66666667%;
    }
    
    .daterangepicker .col-md-7 {
        width: 58.33333333%;
    }
    
    .daterangepicker .col-md-6 {
        width: 50%;
    }
    
    .daterangepicker .col-md-5 {
        width: 41.66666667%;
    }
    
    .daterangepicker .col-md-4 {
        width: 33.33333333%;
    }
    
    .daterangepicker .col-md-3 {
        width: 25%;
    }
    
    .daterangepicker .col-md-2 {
        width: 16.66666667%;
    }
    
    .daterangepicker .col-md-1 {
        width: 8.33333333%;
    }
    
    .daterangepicker .col-md-offset-4 {
        margin-left: 33.333333333%;
    }
}


// .selectionTwoLabels{

// }

//.ng-select.ng-select-single .ng-select-container

.ng-select.selectionTwoLabels .ng-select-container  {            
  height:55px;
  border-radius: 10px;

  
}

// .selectionTwoLabels .ng-dropdown-panel{
//         top: 10px !important;
// }

// .send-to-bank-dropdown .ng-dropdown-panel{
//     top: 10px !important;
// }

// remove arrow from number Input
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}
.labelColor{
    color:#c51244;
    margin-left: 5px;
}   
.hyperlinkstyle{
    // color: #2D197F !important;
    color: #0066CC !important;
} 
.vr_scroll{height: 400px; overflow: hidden; overflow-y: scroll; margin: 5px;}    
.vr_scroll::-webkit-scrollbar-track{ -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);    border-radius: 10px;background-color: #E6E6E6;}    
.vr_scroll::-webkit-scrollbar{    width: 4px;    background-color: #E6E6E6;}    
.vr_scroll::-webkit-scrollbar-thumb{border-radius: 10px;-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);background-color: #5A5A5A;}
.backdrop-bg ion-backdrop.sc-ion-modal-md{opacity: 0.8 !important;}
.d-none{display: none !important;}
.custom-searchbar .searchbar-input-container .searchbar-input{ padding: 7px 45px !important;}

@media screen and (max-width: 768px){
// .thankyou_model .modal-wrapper{position: fixed; bottom: 0 !important; width: 94% !important;border-radius: 15px 15px 0 0;}
ion-modal.thankyou_model::part(content) { position: fixed; bottom: 0 !important; width: 94% !important;border-radius: 15px 15px 0 0; height: 340px !important;}
// .account_activation .modal-wrapper {  position:fixed; width: 100% !important; top: 0; border-radius: 0px;height: 100%; }
ion-modal.account_activation::part(content) { position:fixed; width: 94% !important; bottom: 0; border-radius: 15px 15px 0 0;height: 600px; }
.mbpd_30{padding-bottom: 30px !important;}
.serchbar_help .searchbar-input{box-shadow: none !important;}
// .support .modal-wrapper{position: fixed; bottom: 0 !important; width: 94% !important;border-radius: 15px 15px 0 0;}
ion-modal.support::part(content) { position: fixed; bottom: 0 !important; width: 94% !important;border-radius: 15px 15px 0 0; }
// .superstars .modal-wrapper{position: fixed; bottom: 0; height: 80% !important;width: 100% !important;}
// .superstars .modal-wrapper .ion-page{top:0 !important;}
// above 2 lines replaced by below 2 lines. review
ion-modal.superstars::part(content) { position: fixed; bottom: 0; height: 80% !important;width: 100% !important; }
ion-modal.superstars .ion-page{top:0 !important;}
ion-modal.brokerage-total .ion-page{
    height: 353px !important;
    top: unset !important;
    position: absolute !important;
    bottom: 0 !important;
}
// .summary_details .modal-wrapper{position: fixed; bottom: 0 !important; width: 94% !important;border-radius: 15px 15px 0 0; height: 310px!important;}
ion-modal.summary_details::part(content) { position: fixed; bottom: 0 !important; width: 94% !important;border-radius: 15px 15px 0 0; height: 310px!important;}
// css changed for modal-wrapper	
// .summary_confirm .modal-wrapper{position: fixed; bottom: 0 !important; width: 94% !important;border-radius: 15px 15px 0 0; height: 420px!important;}
ion-modal.summary_confirm::part(content) { position: fixed; bottom: 0 !important; width: 94% !important;border-radius: 15px 15px 0 0; height: 420px!important;}

// .select_Reason .modal-wrapper{position: fixed; bottom: 0 !important; width: 94% !important;border-radius: 15px 15px 0 0; height: 420px!important;}
ion-modal.select_Reason::part(content) { position: fixed; bottom: 0 !important; width: 94% !important;border-radius: 15px 15px 0 0; height: 420px!important;}

.indices .web-equity .header-table th:last-child{padding-right: 16px !important;}

ion-modal.bod_hold_modal::part(content) {position: fixed; bottom: 0 !important; width: 94% !important;border-radius: 15px 15px 0 0; height: 330px!important;}
ion-modal.bod_hold_breakdown_modal::part(content) { position: fixed; bottom: 0 !important; width: 94% !important;border-radius: 15px 15px 0 0; height: 290px!important; }
ion-modal.bod_hold_collatral_modal::part(content) { position: fixed; bottom: 0 !important; width: 94% !important;border-radius: 15px 15px 0 0; height:315px!important; }
ion-modal.phy_fno_confirm_changes::part(content) { position: fixed; bottom: 0 !important; width: 94% !important;border-radius: 15px 15px 0 0; height: 420px!important; }
ion-modal.phy_fno_success::part(content) { position: fixed; bottom: 0 !important; width: 94% !important;border-radius: 15px 15px 0 0; height: 420px!important; }

// .bod_hold_modal .modal-wrapper{position: fixed; bottom: 0 !important; width: 94% !important;border-radius: 15px 15px 0 0; height: 330px!important;}
// .bod_hold_breakdown_modal .modal-wrapper{position: fixed; bottom: 0 !important; width: 94% !important;border-radius: 15px 15px 0 0; height: 290px!important;}
// .bod_hold_collatral_modal .modal-wrapper{position: fixed; bottom: 0 !important; width: 94% !important;border-radius: 15px 15px 0 0; height:290px!important;}
// .phy_fno_confirm_changes .modal-wrapper{position: fixed; bottom: 0 !important; width: 94% !important;border-radius: 15px 15px 0 0; height: 420px!important;}
// .phy_fno_success .modal-wrapper{position: fixed; bottom: 0 !important; width: 94% !important;border-radius: 15px 15px 0 0; height: 420px!important;}
.Physical_fno_mobile_view .custom-searchbar .searchbar-input-container .searchbar-input {
    padding: 6px 15px 6px 40px !important; text-align: left;}
    .ledger-popover{
        .popover-content {
           width: 310px !important;
           transform: translate(22%, 8%);
        }
    }
}

ion-header{z-index:1060;}
// client summary mobile css
.custom_sr_client .searchbar-input-container .searchbar-input{text-align: left;}
.custom_sr_client .searchbar-search-icon.sc-ion-searchbar-md{width: 16px;}
// client summary mobile css
.dp_script_main .custom-searchbar .searchbar-input-container .searchbar-input{padding: 7px 20px 7px 0px !important;}
.vr_middle .sorting-img-ascending{margin-top: -17px !important;}
.vr_middle .sorting-img-descending{margin-top: -17px !important;}
// .client_mapping_fail .modal-wrapper{width: 430px !important; height: 450px!important; border-radius: 10px;}
ion-modal.client_mapping_fail::part(content) { width: 430px !important; height: 450px!important; border-radius: 10px; }
@media ( max-width:767px)	
{
// .client_mapping_fail .modal-wrapper { width: 100% !important; height: 438px !important;  position: absolute; bottom: 0; left: 0; right: 0; border-radius: 20px 20px 0 0; }
ion-modal.client_mapping_fail::part(content) { width: 100% !important; height: 438px !important;  position: absolute; bottom: 0; left: 0; right: 0; border-radius: 20px 20px 0 0; }
}

.dp_script_main .custom-searchbar .searchbar-input-container .searchbar-input{padding: 7px 20px 7px 0px !important;}
.vr_middle .sorting-img-ascending{margin-top: -17px !important;}
.vr_middle .sorting-img-descending{margin-top: -17px !important;}

@media screen and (max-width: 500px) {
    .date_select_drop .ng-dropdown-panel .ng-dropdown-panel-items {
        max-height: 150px !important;
    }
    .custom-searchbar .searchbar-input-container .searchbar-input{ padding: 6px 21px 6px 35px !important;}
}
.lead_table_search_filter .custom-searchbar .searchbar-input-container .searchbar-input{padding: 7px 0px 7px 16px !important; border: 0px;}
.lead_table_search_filter .search-section-changed-revamped .search-button img{width: 20px;}
.lead_table_search_filter .search-section-changed-revamped .search-button{width: 30px; margin: 0;}
.lead_cards .swiper-pagination-bullets{bottom: -5px!important;}
.lead_cards .swiper-pagination-bullet{background:#8571cc;}
.lead_cards .swiper-pagination-bullet-active{background: #5739BC;}
.lead_cards .swiper-pagination-bullet{width: 12px; height: 12px;}
.serchbar_help .searchbar-input {box-shadow: none; border: none;padding: 8px 16px;box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);}

.amc_search .searchbar-input {padding: 0px 15px !important; background: inherit;
    box-shadow: inherit; text-align: center;}

.amc_non_client .off{
    position: inherit !important;
}
.amc_non_client .md-drppicker{margin-top: 10px !important;}

.custom-date-block .md-drppicker {width: 100% !important; left: 0 !IMPORTANT; top: 59px !important;}

.custom-date-block-one .md-drppicker {width: 600px!important;
    right: 0!important;
    top: 50px!important;
    left: inherit!important;
    margin-top: 0!important;}

.custom-date-block .ranges{width: 100% !important;}
.custom-date-block-one .ranges{width: 100% !important;}

.md-drppicker {
    .ranges ul li {
        button {
            color: #444;
        }
        button.active {
            background-color: #3f51b5;
            color: #fff;
            background-color: #f37921 !important;
        }
    }

    td.active,  td.active:hover {
        background: #f37921 !important;
    }
    .btn {
        background: transparent !important;
        box-shadow: none !important;
        color: #f37921 !important;
        text-transform: capitalize !important;
        font-size: 14px !important;
        font-family: 'nunitoSemiBold' !important;
    }
}

.raise_main_tbl th:first-child, .raise_main_tbl td:first-child{width: 12% !important;}

@media screen and (min-width: 501px) and (max-width: 767px)  {
.header-segment-with-border-parent .search-filter-parent-mobile{width: calc(100vw - 90px);}
.search-section-changed-revamped .custom-searchbar{width:100% !important;}
.common-header-all .back-drop-down-section .hide-in-mobile{display: none;}
.revamp-drop-down .business-monitor-block{padding: 10px;}
.search-section-changed-revamped{margin: 10px; padding: 10px !important;}
.date_main_grid{display: none;}
.dashbaord-container{padding: 0 8px;}
.header-segment-bottom ion-segment-button{font-size: 12px !important;}
.calendar-date-block-section .w-450{width: 450px !important;}
.content-block{padding: 0 10px;}
.expiry-date-block{padding: 10px !important;}
.content-block .ltp-block .ltp-section {width: 100% !important;padding: 0 16px !important;}
.ltp-block{flex-wrap: wrap;}
.ltp-block .table-block{width: 100% !important;}
.inner-content {
    // height: calc(100vh - 417px);
    // height:  calc(100vh - 465px);
    height: calc(100vh - 252px);
}
.search-section-changed-revamped {
   width: 100%;
    position: relative;
    left: -8px;
    border-radius: 0;
    margin-bottom: 5px;
    .sip-value-block {
        margin: 0 0 10px 0; width: 100%;
    }
}
.web-equity {
    .header-table {
        th {
            font-size: 12px;
            .updated-sorting-img {
                margin-left: 2px !important;
            }
        }
        th:last-child {
            padding-right: 7px !important;
        }
    }
}
.detail-part-dashboard {
    width: 100%;
    .header-part {
        border-radius: 10px;
    }
}
.detail-part-dashboard.detail-part-aum-inner {
    // max-height: calc(100vh - 463px);
    // overflow: auto;
    .main-table {
        max-height: unset !important;
    }
}
.max-height-unset-in-mobile {
    max-height: unset !important;
}
.dashbaord-container {
    // top: -85px;
    top: unset;
    padding: 0 8px;
}
.common-header-all {
    height: 184px !important;
    height: unset !important;
    .header-bg {
        position: absolute;
        background: #F7F7F7;
        height: 164px !important;
        width: 100vw;
    }
    .back-drop-down-section {
        padding-left: 16px;
        margin-top: 8px;
    }
}
.common-content-section {
    .card-type-segment {
        flex-wrap: nowrap !important;
        width: auto;
        overflow: auto;
        ion-segment-button {
            flex: 0 1 auto;
            width: unset;
            // min-width: 45vw !important;
            // min-width: unset !important;
            min-width: 201px !important;
        }
    }
    .custom-searchbar .searchbar-input-container .searchbar-input{ padding:5px 0 !important;}
}
.search-section-changed-revamped .search-button img{width: 15px; height: 15px;}
.code{display: block;}
.detail-part-aum-inner .code-block .name{font-size: 14px;}
.detail-part-aum-inner .code-block .name{white-space: normal;}
.client_summary_mb_search .custom-searchbar .searchbar-input-container .searchbar-input {
    padding: 6px 15px 6px 40px !important; text-align: left;}


@media screen and (min-width: 501px) and (max-width: 1200px)  {
.common-content-section .card-type-segment { flex-wrap: nowrap !important; width: auto; overflow: auto; }
.common-header-all .back-drop-down-section .back-section .type-section{font-size: 20px;}
.header-segment-bottom ion-segment-button{font-size: 12px !important;}
.nifty-block .label, .nifty-block .value, .nifty-block .changed-value{font-size: 15px !important;}
.web-equity .header-part .title, .web-equity .header-part .value, .web-equity .header-part .rupees, .web-equity .header-part .percent-value{font-size: 16px !important;}
.total-value-block .section .section-title, .code, .equity-block .block .block-value{font-size: 16px !important;}
.total-value-block .section .section-value{font-size: 18px !important;}
.icon-block span{font-size: 14px;}
.leger_web_eq .header-part{flex-wrap: wrap !important;}
.pledge{padding-top: 15px;}
.header-segment-with-border-parent .custom-searchbar{margin-left: 25px;}
.common-content-section .card-type-segment ion-segment-button{min-width: 190px !important;}
.search-section-changed-revamped .custom-searchbar{width: 100% !important;}
.desktop-table input[type=checkbox], input[type=radio]{width: 10px; height: 10px;}
}


.amc_search .searchbar-input {padding: 0px 15px !important; background: inherit;
    box-shadow: inherit; text-align: center;}

.amc_non_client .off{
    position: inherit !important;
}

.amc_non_client .md-drppicker{margin-top: 10px !important;}
.brokerage_search{overflow: inherit !important;}
// .brokerage_datepicker .md-drppicker {width: 600px !important; right: 0 !IMPORTANT; top: 50px !important; left: inherit !important; margin-top: 0px !important;}

@media screen and (min-width: 501px) and (max-width: 1200px)  {
.reset-section-mobile {display: flex;align-items: center;cursor: pointer;
    .reset-block {display: flex;align-items: center;
    .reset-img {width: 18px;}
    .reset {margin-left: 8px;font-size: 16px;}
    }
}
.calculator {
.form-block {width: 100%;padding: 0 16px;}
.login-btn {margin: 46px auto;
    ion-button {height: 60px;}
}
.field-block {
    ion-item {
.field {margin-top: 22px !important;
    ion-input {font-size: 16px;height: 40px;}}
}
}
}

}
.script_search .custom-searchbar .searchbar-input-container .searchbar-input{padding: 7px 10px !important;}

@media screen and  (max-width: 768px)  {
    .segment-search-block {padding: 0 16px;flex-direction: column;
    .header-segment-top {justify-content: center;margin: 0;
    ion-segment-button {height: 32px;}
    }
    ion-searchbar {width: 100%;margin-top: 4px;}
}


}
@media screen and (max-width: 768px) {
    .amc_search .searchbar-input { padding: 5px 15px !important; background: inherit;
    box-shadow: inherit; text-align: left; border: 1px solid #ccc; border-radius: 10px; margin-top: 10px;}
    .amc_search ion-searchbar{padding: 0px; border-bottom: 0px !important;}
    .amc_search ion-segment{width: 250px;}
    .amc_search ion-toolbar{--padding-start: 0px !important; --padding-end:0px !important;}
    .amc_search ion-segment ion-segment-button{margin: 0 4px; border-radius: 30px;}
    .amc_search ion-label{color: #000;}
    }
@media screen and (min-width: 768px) and (max-width: 1199px)  {
    .mega-menu .more-option-block{padding: 17px 20px;}
    .mega-menu .more-option-block .item{    padding: 8px 5px;}
.brokerage_search .custom-searchbar .searchbar-input-container .searchbar-input {
padding: 6px 15px 6px 45px !important; text-align: left;}
.brokerage_search .search-section-changed-revamped{ left: inherit !important;}
.brokerage_search .custom-searchbar{ width: 100% !important; height: 40px !important;}
.brokerage_search .search-section{margin: 10px 0px !important;}
.search-section-changed-revamped{margin: 0px !important;}
}
}
.mega-menu .more-option-block{padding: 17px 20px;}
.mega-menu .more-option-block .item{    padding: 8px 5px;}

// .query_details_modal .modal-wrapper{width:800px !important ; height: 400px !important; border-radius: 15px; position: relative;}
ion-modal.query_details_modal::part(content) { width:800px !important ; height: 400px !important; border-radius: 15px; position: relative; }

// .interactions_details_modal .modal-wrapper{width:800px !important ; height: 340px !important; border-radius: 15px; position: relative;}
ion-modal.interactions_details_modal::part(content) {width:800px !important ; height: 340px !important; border-radius: 15px; position: relative;}
 
// .query_details_modal .modal-wrapper{width:800px !important ; height: 400px !important; border-radius: 15px; position: relative;}
ion-modal.query_details_modal::part(content) {width:800px !important ; height: 400px !important; border-radius: 15px; position: relative;}

// .interactions_details_modal .modal-wrapper{width:800px !important ; height: 340px !important; border-radius: 15px; position: relative;}
ion-modal.interactions_details_modal::part(content) {width:800px !important ; height: 340px !important; border-radius: 15px; position: relative;}

.infoTooltip {
    position: relative;
    display: inline-block;
     img{
        vertical-align: middle;
     }
  
    .tooltiptext {
    visibility: hidden;
    width: 264px;
    background: #FFFFFF;
    box-shadow: 0px 2px 20px rgba(140, 140, 140, 0.48);
    color: #000;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 50%;
    font-size: 13px;
    opacity: 0;
    //transition: opacity 1s;
    transform: translate(-50%, 10px);
    &::before{
        content: '';
        display: block;
        width: 0; 
  height: 0; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 0);

    }
  }
  
  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}
// css changed for modal-wrapper	
// .leads_status_modal .modal-wrapper{width:700px !important ; border-radius: 15px; position: relative;}
ion-modal.leads_status_modal::part(content) { width:700px !important ; border-radius: 15px; position: relative;}


// .query_details_modal .modal-wrapper{width:800px !important ; height: 400px !important; border-radius: 15px; position: relative;}
ion-modal.query_details_modal::part(content) { width:800px !important ; height: 400px !important; border-radius: 15px; position: relative; }

// .interactions_details_modal .modal-wrapper{width:800px !important ; height: 340px !important; border-radius: 15px; position: relative;}
ion-modal.interactions_details_modal::part(content) {width:800px !important ; height: 340px !important; border-radius: 15px; position: relative;}

.infoTooltip {
    position: relative;
    display: inline-block;
     img{
        vertical-align: middle;
     }
  
    .tooltiptext {
    visibility: hidden;
    width: 264px;
    background: #FFFFFF;
    box-shadow: 0px 2px 20px rgba(140, 140, 140, 0.48);
    color: #000;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 50%;
    font-size: 13px;
    opacity: 0;
    //transition: opacity 1s;
    transform: translate(-50%, 10px);
    &::before{
        content: '';
        display: block;
        width: 0; 
  height: 0; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 0);

    }
  }
  
  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}

// css changed for modal-wrapper	
// .leads_status_modal .modal-wrapper{width:700px !important ; border-radius: 15px; position: relative;}
ion-modal.leads_status_modal::part(content) { width:700px !important ; border-radius: 15px; position: relative;}

// .client_profile_modal .modal-wrapper{width:750px !important ; height: 440px !important; border-radius: 15px; position: relative;}
ion-modal.client_profile_modal::part(content) { width:750px !important ; height: 440px !important; border-radius: 15px; position: relative; }

.search-section-reports.scriptwise span{color: #000;}

// css changed for modal-wrapper	
ion-modal.brokerage_access_panel_modal::part(content) { width:700px !important ; height: 480px !important; border-radius: 15px; position: relative;}
// .brokerage_access_panel_modal .modal-wrapper{width:700px !important ; height: 480px !important; border-radius: 15px; position: relative;}

#BrokeragePanel .search-section-changed-revamped .custom-searchbar { width:80% !important; height: 30px;} 
#BrokeragePanel .search-section-changed-revamped{padding: 0px 12px;margin: -12px -0 0 0;border: none !important; width: 65%;}
.table-header{
    margin-top: 14px;
}

.search-section-reports.scriptwise span{color: #000;}
#BrokeragePanel  .sc-ion-searchbar-md-h{padding: 0px !important;}
#BrokeragePanel .custom-searchbar .searchbar-input-container .searchbar-input { padding: 7px 30px!important; font-size: 14px; }
// quick-link-css
.quick_links{ background: #f37921; font-size: 14px; position: fixed; left: -30px; transform: rotate(-90deg); padding: 8px; border-radius:0 0 15px 15px; color: #fff; cursor: pointer; z-index: 1061; width: 110px; text-align: center; top: 45%; margin-top: -10px;font-family: "nunitoSemiBold";}
.quick_link_menu{background: transparent;
height: 158px;
width:45px;transition: width 1s;
position: fixed; left: -10px; top: 50%; transform: translateY(-50%); padding: 10px;  color: #fff; z-index: 1060; vertical-align: middle;}
.quick_link_menu:hover{
width: 430px;
}
.quick_menu{ display: grid; grid-template-columns: 1fr 1fr; gap: 10px; margin: 0;
padding: 32px 18px 32px 45px;background:#f37921;;border-radius:0 15px 15px 0;
transform: translate(-400px, 0); width:400px; -webkit-transition: all 1s;-moz-transition: all 1s;-ms-transition: all 1s;-o-transition: all 1s;transition: all 1s;}
.quick_menu a{text-decoration: none; color: #fff; font-size: 13px; }
.quick_links:hover + .quick_menu {transform: translate(0px, 0);-webkit-transition: all 1s;-moz-transition: all 1s;-ms-transition: all 1s;-o-transition: all 1s;transition: all 1s;}
.quick_menu:hover {transform: translate(0px, 0);-webkit-transition: all 1s;-moz-transition: all 1s;-ms-transition: all 1s;-o-transition: all 1s;transition: all 1s;}
.quick_menu.quicklinkboxremove{
	transform: translate(-400px, 0); width:400px; -webkit-transition: all 1s;-moz-transition: all 1s;-ms-transition: all 1s;-o-transition: all 1s;transition: all 1s;
	}
// quick-link-css

@media screen and  (max-width: 768px)  {
    // .client_profile_modal .modal-wrapper{width: 100% !important; height: 500px !important; border-radius: 15px 15px 0 0; position: absolute; bottom: 0;} 
    ion-modal.client_profile_modal::part(content) { width: 100% !important; height: 500px !important; border-radius: 15px 15px 0 0; position: absolute; bottom: 0; }
    ion-modal.edit-ip-details::part(content) { width: 100% !important; height: 500px !important; border-radius: 15px 15px 0 0; position: absolute; bottom: 0; }
    // .query_details_modal .modal-wrapper{width: 100% !important;  border-radius: 15px 15px 0 0; position: absolute; bottom: 0; height: 500px !important;}
    ion-modal.query_details_modal::part(content) { width: 100% !important;  border-radius: 15px 15px 0 0; position: absolute; bottom: 0; height: 500px !important; }

    // .interactions_details_modal .modal-wrapper{width: 100% !important;  border-radius: 15px 15px 0 0; position: absolute; bottom: 0;height: 500px !important;}
    ion-modal.interactions_details_modal::part(content) { width: 100% !important;  border-radius: 15px 15px 0 0; position: absolute; bottom: 0;height: 500px !important; }

    .common-content-section.querirstab .card-type-segment ion-segment-button{min-width: 45% !important;}  
}
.header-md{
    box-shadow:none;
    -webkit-box-shadow:none;
}
.tab-flex{
    display: flex;
    justify-content: flex-start;
}
.input-highlight.sc-ion-input-md{
    background: none!important;
}
.has-focus.ion-valid.sc-ion-input-md-h, .ion-touched.ion-invalid.sc-ion-input-md-h{
    --border-color:#000!important;
}
.has-focus.sc-ion-input-md-h input.sc-ion-input-md {
    caret-color: #000!important;;
}
///
#chartHide .swiper-pagination-bullets {bottom: -5px!important}
#chartHide .swiper-pagination-bullet {background: #ff9951}
#chartHide .swiper-pagination-bullet-active {background: #f37921;width: 30px !important; 
border-radius: 10px !important;
}
#chartHide .swiper-pagination-bullet {width: 14px;height: 14px}
.brokerage_datepicker .md-drppicker {right: 0 !IMPORTANT; top: 50px !important; left: inherit !important; margin-top: 0px !important;}
@media screen and (min-width:769px) {
    .brokerage_datepicker .md-drppicker {width: 600px !important;}
}
.section  .calendar { position: absolute; right: 10px; bottom: 9px; }

// css for modal-quick-link
ion-modal.quick-link-modal::part(content) { width:500px !important ; height: 440px !important; border-radius: 15px; position: relative; }
@media screen and (max-width:769px) {
ion-modal.quick-link-modal::part(content) { width: 100% !important;  border-radius: 15px 15px 0 0; position: absolute; bottom: 0; height: 550px !important; }
}
// css for modal-quick-link

// css for jv-response-reason
ion-modal.jv-response-reason::part(content) { width:650px !important ; height: 440px !important; border-radius: 15px; position: relative; }
@media screen and (max-width:769px) {
ion-modal.jv-response-reason::part(content) { width: 100% !important;  border-radius: 15px 15px 0 0; position: absolute; bottom: 0; height: 550px !important; }
}

// css for jv-reject-reason
ion-modal.jv-reject-reason::part(content) { width:350px !important ; height: 230px !important; border-radius: 15px; position: relative; }
@media screen and (max-width:769px) {
ion-modal.jv-reject-reason::part(content) { width: 100% !important;  border-radius: 15px 15px 0 0; position: absolute; bottom: 0; height: 250px !important; }
}

// css changed for modal-wrapper	
ion-modal.demap-client-details::part(content) {
    max-width: 900px !important;
    width: 900px !important;
    border-radius: 8px;
}
ion-modal.demap-client-details {
    .ion-page {
        padding: 30px 5px 35px;
        .card_details_pop{
            width: 100% !important;
            max-width: 100% !important;
        }
    }
}
.date-no-opacity{
    opacity: inherit !important;
}

// css for modal-quick-link
ion-modal.having-birthday-modal::part(content) { width:750px !important ; height: 440px !important; border-radius: 15px; position: relative; }
@media screen and (max-width:769px) {
ion-modal.having-birthday-modal::part(content) { width: 100% !important;  border-radius: 15px 15px 0 0; position: absolute; bottom: 0; height: 500px !important; }
}

@media screen and (min-width: 1440px) {
    .container, .container-change{ width: 1340px !important; margin: 0 auto;}
}

@media screen and (min-width: 1920px) {
    .container, .container-change{ width: 1600px !important; margin: 0 auto; }

}

.w-180{
    width: 180px !important;
}
ion-modal.become-partner::part(content) {
    // height: 400px;   og size
    height: 244px;
    width: 50%;
    border-radius: 15px;
}

@media screen and (max-width:576px){
    ion-modal.become-partner::part(content) {
        height: 250px;
        width: 88%;
    }
}

ion-modal.not-clickable-tabs::part(content) {height: 120px;width: 40%;border-radius: 15px;}
@media screen and (max-width:576px){ion-modal.not-clickable-tabs::part(content) {height: 135px;width: 88%;}
}

.circular_search .custom-searchbar .searchbar-input-container .searchbar-input {
padding: 6px 15px 6px 40px !important;}
.circular_search .searchbar-clear-button.sc-ion-searchbar-md {inset-inline-end: 35px;}
ion-modal.guest-journey-modal::part(content) { width:600px !important ; height: 440px !important; border-radius: 15px; position: relative; }
@media screen and (max-width:769px) {
ion-modal.guest-journey-modal::part(content) { width: 95% !important;  position: absolute;  height: 400px !important; margin: 0 auto; bottom: 0;border-radius: 15px 15px 0 0; }
}
ion-modal.guest-onboarding-modal::part(content) { width:600px !important ; height: 470px !important; border-radius: 15px; position: relative; }
@media screen and (max-width:769px) {
ion-modal.guest-onboarding-modal::part(content) { width: 100% !important;  border-radius: 15px 15px 0 0; position: absolute; bottom: 0; height: 500px !important; }
}

ion-modal.add-sub-master-modal::part(content) { width:700px !important ; height: 500px !important; border-radius: 15px; position: relative; }
@media screen and (max-width:769px) {
ion-modal.add-sub-master-modal::part(content) { width: 100% !important;  border-radius: 15px 15px 0 0; position: absolute; bottom: 0; height: 500px !important; }
}
.search-box .searchbar-clear-button.sc-ion-searchbar-md {inset-inline-end: 0px;}
.top-full-search .searchbar-clear-button.sc-ion-searchbar-md {inset-inline-end: 15px;}
@media screen and (max-width:768px){
    ion-modal.support-modal-height.ion-page {
        top: calc(100% - 404px) !important;
        height: 100% !important;
    }
}
@media screen and (max-width:500px){
    ion-modal.client-details::part(content) {
        margin: 0 auto !important;
    }
    ion-modal.client-details .ion-page{
        top:calc(100% - 507px) !important;
        // width: 385px;
    }
}
@media screen and (max-width:768px){
    ion-modal.search-modal::part(content) {
        margin: 0 !important;
        height: 100% !important;
        background: transparent;
    }
    ion-modal.search-modal {
        .ion-page {
            border-radius: 8px 8px 0 0 !important;
            padding: 16px 16px 0px !important;
            height: 90vh;
            background: #fff;
            top: calc(100% - 90vh);
        }
    }
}
.total-search .custom-searchbar .searchbar-input-container .searchbar-input{ padding: 7px 15px !important;}

.custom-new-calendar{border-bottom: 1px solid #ccc; display: flex;height:40px; align-items: center; justify-content: space-between;
input{border: 0px; width: 100%; background: transparent; outline: none !important;}
.mat-mdc-icon-button.mat-mdc-button-base{--mdc-icon-button-state-layer-size:inherit; padding: 0px; visibility: hidden;}
.mat-datepicker-toggle {
    background: url("assets/svg/calendar_report.svg") no-repeat !important;
}
}
.mat-datepicker-content .mat-calendar{margin-bottom: -8px !important;}
.mat-calendar-body-selected{background-color:#F37921 !important; color: #fff !important;}
.mat-calendar-body-cell:hover .mat-calendar-body-cell-content{background:#f3792187 !important;}
.mat-calendar-body-cell-content:hover{background:#f3792187 !important;}
.custom-new-calendar ion-label{font-size: 12px;}
.mat-calendar-body-active .mat-calendar-body-cell-content{background-color:#f37921 !important; border: 1px solid #f37921 !important;color: #fff !important;}
.mat-calendar-controls{margin-top: 0 !important;}

.top-filter ion-searchbar .searchbar-input{box-shadow: none;    border: 1px solid #ccc;    height: 36px;}
.top-filter .searchbar-search-icon.sc-ion-searchbar-md{top: 8px;}
// manager mapping searchbox
ion-modal.search-modal::part(content) { width: 600px;height: calc(100% - 10vh);}

.app-view-header{ align-items: center; background: #F9F9FB;
    .title-block-custom{background: #F9F9FB !important;}
    .icon-title{display: flex;}
    .title{font-size: 20px; margin-right: 16px; padding-left: 8px; color: #282828 !important;font-family: nunitoBold;}
    ion-icon { font-size: 24px; margin-bottom: 1px; position: relative; left: -2px; color: #282828 !important; }
}

ion-modal.onlineFixed-modal::part(content) { 
    max-width: 420px;  
    // height: 250px;
    height: 450px;
    border-radius: 8px;
}
.onlineFixed-modal{
        .ion-page {
            position: absolute;
            padding: 20px 20px 25px;}

        .title {
            display: flex;
            justify-content: center;
            color: #000;
            font-family: nunitoExtraBold;
            font-size: 16px;
            span {
                border-bottom: 3px solid var(--ion-red-secondary-color);
                padding: 0 5px 2px;
            }
        }
    }
@media screen and (max-width:768px){
.family_head_search{
ion-toolbar .sc-ion-searchbar-md-h.serachReprotsInput{padding-left: 0px !important;}
.search-section-changed-revamped{left: 0 !important;}
}
}

// css for guest-onboarding-modal
ion-modal.guest-onboarding-journey-modal::part(content) { width:auto ; height: 90%; border-radius: 15px; position: relative; }
@media screen and (max-width:769px) {
ion-modal.guest-onboarding-journey-modal::part(content) { width: 100% !important;  border-radius: 15px 15px 0 0; position: absolute; bottom: 0; height: auto !important; }
}
@media screen and (min-width:1400px) {
    ion-modal.guest-onboarding-journey-modal::part(content) {height:75%;}
    .steps-field.thin-scroll{height: calc(75vh - 215px) !important; overflow: auto; overflow-y: auto;}
    .step-banner{height: calc(75vh - 160px) !important;}
}
@media screen and (min-width:1800px) {
    ion-modal.guest-onboarding-journey-modal::part(content) {height:auto;}
    .steps-field.thin-scroll{height: inherit !important; overflow: auto; overflow-y: auto;}
    .step-banner{height: inherit !important;}
}
// css for guest-onboarding-modal
